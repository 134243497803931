import { gettext, interpolate } from "@multimediallc/web-utils"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { normalizeResource } from "../../../routes/util"
import { Typography } from "../../common"
import "./ContestPayoutInfo.scss"

const i18n = {
    payoutInformation: gettext("Payout Information"),
    payoutInformationExplanation: (languageCode: string): string => {
        return interpolate(
            gettext(
                'Payout information is available in the <a class="hrefColor" href="%(link)s" target="_blank" data-testid="contest-affiliate-link">Chaturbate affiliate program</a>.',
            ),
            { link: normalizeResource(languageCode, "/affiliates") },
            true,
        )
    },
    previousWinnerInfo: (languageCode: string): string => {
        return interpolate(
            gettext(
                'You can check if you\'ve won by looking in the "Previous Winners" tab of the <a class="hrefColor" href="%(detailsUrl)s" target="_blank" data-testid="contest-details-link-payout">contest details</a>, or you can check the <a class="hrefColor" href="%(affiliatesStatsUrl)s" target="_blank" data-testid="contest-affiliate-stats-link">affiliate program stats</a>.',
            ),
            {
                detailsUrl: normalizeResource(languageCode, "/contest/details"),
                affiliatesStatsUrl: normalizeResource(
                    languageCode,
                    "/affiliates/stats",
                ),
            },
            true,
        )
    },
}

export function ContestPayoutInfo() {
    const languageCode = useAppContext().context.language_code

    return (
        <div className="ContestPayoutInfo">
            <Typography
                weight={Weights.medium}
                component="h2"
                className="label heading"
                data-testid="contest-payout-info-label"
            >
                {i18n.payoutInformation}
            </Typography>
            <Typography
                size={Sizes.xs}
                component="p"
                dangerouslySetInnerHTML={{
                    __html: i18n.payoutInformationExplanation(languageCode),
                }}
                data-testid="contest-payout-info-text"
            />
            <Typography
                size={Sizes.xs}
                component="p"
                dangerouslySetInnerHTML={{
                    __html: i18n.previousWinnerInfo(languageCode),
                }}
                data-testid="contest-payout-previous-winner-text"
            />
        </div>
    )
}
