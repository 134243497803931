import { gettext } from "@multimediallc/web-utils"
import { Sizes } from "../../../interfaces/sizing"
import { colorClass } from "../../../utils/css"
import { Link, Typography } from "../../common"
import "./ContestStatsTopSection.scss"

const i18n = {
    refreshStats: gettext("Refresh Stats"),
    seeContestDetails: gettext("See contest details"),
    contestWinnerPrizeMessage: gettext(
        "Every hour, the cam with the most points wins a $10 prize. The cam in 2nd place wins a $5 prize.",
    ),
}

interface ContestStatsTopSectionProps {
    /** Whether to show the refresh stats button and the contest details link */
    withRefreshButtonAndDetails: boolean
    /** Function trigger when refresh stats is clicked */
    refreshButtonOnClick: () => void
}

export function ContestStatsTopSection({
    withRefreshButtonAndDetails,
    refreshButtonOnClick,
}: ContestStatsTopSectionProps) {
    return (
        <div className="ContestStatsTopSection">
            {withRefreshButtonAndDetails && (
                <Typography
                    component="button"
                    size={Sizes.sm}
                    className="refreshButton hrefColor link"
                    data-testid="contest-refresh-stats-button"
                    onClick={refreshButtonOnClick}
                >
                    {i18n.refreshStats}
                </Typography>
            )}
            <Typography
                size={Sizes.xs}
                component="p"
                data-testid="contest-explanation-text"
                className={`explanation ${colorClass.defaultTooltipColor}`}
            >
                {i18n.contestWinnerPrizeMessage}
                {withRefreshButtonAndDetails && (
                    <Link
                        href="/contest/details"
                        className="hrefColor"
                        data-testid="contest-details-link-header"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {` ${i18n.seeContestDetails}.`}
                    </Link>
                )}
            </Typography>
        </div>
    )
}
