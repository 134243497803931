import "./MembershipActionContainer.scss"
import { gettext } from "@multimediallc/web-utils"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Button, ButtonColor, Typography } from "../../common"

const i18n = {
    cancelText: gettext("cancel"),
    cantRenew: gettext("can't renew"),
}

interface MembershipActionContainerProps {
    cancelled: boolean
    onClick: () => void
}

export function MembershipActionContainer({
    cancelled,
    onClick,
}: MembershipActionContainerProps) {
    return (
        <Typography
            className="MembershipActionContainer"
            weight={Weights.medium}
            size={Sizes.xs}
        >
            {cancelled ? (
                <div className="membershipLightText">
                    {`(${i18n.cantRenew})`}
                </div>
            ) : (
                <Button
                    text={i18n.cancelText}
                    onClick={onClick}
                    color={ButtonColor.Text}
                    className="hrefColor actionButton"
                />
            )}
        </Typography>
    )
}
