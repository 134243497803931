import { useCallback, useEffect, useState } from "react"
import { gettext } from "@multimediallc/web-utils"

const i18n = {
    thankYouForYourFeedback: gettext("Thank you for your feedback"),
    noLongerNeedBenefits: gettext("No longer need Supporter Benefits"),
    specificallyJoinedForBonusTokens: gettext(
        "Specifically joined for the 200 bonus tokens",
    ),
    didNotUseBenefits: gettext("Did not use Supporter Benefits"),
    mistakenlyJoined: gettext("Mistakenly joined recurring membership"),
    tooExpensive: gettext("Too Expensive"),
    dissatisfiedWithBenefits: gettext("Dissatisfied with Supporter Benefits"),
}

export const CancelReasonOptions: string[][] = [
    [i18n.noLongerNeedBenefits, "no-longer-need-benefits"],
    [i18n.specificallyJoinedForBonusTokens, "joined-for-bonus-tokens"],
    [i18n.didNotUseBenefits, "did-not-use-benefits"],
    [i18n.mistakenlyJoined, "mistakenly-joined"],
    [i18n.tooExpensive, "too-expensive"],
    [i18n.dissatisfiedWithBenefits, "dissatisfied-with-benefits"],
]

const RANDOMIZED_OPTIONS_STORAGE_KEY = "randomizedOptions"
export const SAVED_RESPONSE_STORAGE_KEY = "savedResponse"

export const useCancelOptions = (): { feedbackFormOptions: string[][] } => {
    const [feedbackFormOptions, setFeedbackFormOptions] =
        useState<string[][]>(CancelReasonOptions)

    const setOrCreateRandomizedOptions = useCallback(() => {
        const options = window.localStorage.getItem(
            RANDOMIZED_OPTIONS_STORAGE_KEY,
        )
        if (options !== null) {
            setFeedbackFormOptions(JSON.parse(options))
        } else {
            const newRandomizedOption = [...CancelReasonOptions].sort(
                () => Math.random() - 0.5,
            )
            setFeedbackFormOptions(newRandomizedOption)
            window.localStorage.setItem(
                RANDOMIZED_OPTIONS_STORAGE_KEY,
                JSON.stringify(newRandomizedOption),
            )
        }
    }, [])

    useEffect(() => {
        function resetStorage() {
            window.localStorage.removeItem(RANDOMIZED_OPTIONS_STORAGE_KEY)
        }
        // Options should only be re-randomized upon every refresh, so we clear this attribute from local storage.
        window.addEventListener("pagehide", resetStorage)
        setOrCreateRandomizedOptions()
        return () => {
            window.removeEventListener("pagehide", resetStorage)
        }
    }, [setOrCreateRandomizedOptions])

    return { feedbackFormOptions }
}
