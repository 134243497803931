import type React from "react"
import { Fragment } from "react"
import { colorClass } from "../../../utils/css"
import { quickHash } from "../../../utils/quickHash"
import { Link } from "../../common"

type RoomSubjectPart = {
    msg: string
    isTag: boolean
    key: string
}

export const processRoomSubject = (
    text: string,
    approvedTags: string[],
    onHashtagClick?: (ev: React.MouseEvent<HTMLAnchorElement>) => void,
) => {
    const stringParts = text.split(/#[a-zA-Z0-9\-_]{2,50}/g)
    const hashtagParts = text.match(/#[a-zA-Z0-9\-_]{2,50}/g) || []

    const textHash = quickHash(text)

    const titleParts: RoomSubjectPart[] = []
    let key = 0
    for (let i = 0; i < stringParts.length; i += 1) {
        titleParts.push({
            msg: stringParts[i],
            isTag: false,
            key: `${textHash}-${key}`,
        })
        key++
        if (i < hashtagParts.length) {
            titleParts.push({
                msg: hashtagParts[i],
                isTag: approvedTags.includes(
                    hashtagParts[i].substring(1).toLowerCase(),
                ),
                key: `${textHash}-${key}`,
            })
            key++
        }
    }

    return (
        <Fragment>
            {titleParts.map((part) => {
                if (part.isTag) {
                    return (
                        <Link
                            href={`/tag/${part.msg.substring(1)}`}
                            className={colorClass.camSubjectTagColor}
                            key={part.key}
                            onClick={(ev) =>
                                onHashtagClick !== undefined &&
                                onHashtagClick(ev)
                            }
                        >
                            {part.msg}
                        </Link>
                    )
                }
                return <span key={part.key}>{part.msg}</span>
            })}
        </Fragment>
    )
}
