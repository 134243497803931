import { type ChangeEvent, useCallback, useState } from "react"
import "./MembershipDurationContainer.scss"
import { gettext } from "@multimediallc/web-utils"
import { AlertProvider } from "../../../hooks/useAlert"

import { MembershipActionContainer } from "../MembershipActionContainer"
import { MembershipDurationText } from "../MembershipDurationText"
import { MembershipFeedbackForm } from "../MembershipFeedbackForm"
import { SaveNotice } from "../SaveNotice"
import { useCancelOptions } from "../useCancelOptions"

interface MembershipDurationContainerProps {
    cancelled: boolean
    membershipId: string
    expiredAt?: string
}

const i18n = {
    thankYouForYourFeedback: gettext("Thank you for your feedback"),
}

export function MembershipDurationContainer({
    cancelled,
    expiredAt,
    membershipId,
}: MembershipDurationContainerProps) {
    const [membershipCancelled, setMembershipCancelled] = useState(false)
    const [showFeedbackForm, setShowFeedbackForm] = useState(false)
    const { feedbackFormOptions } = useCancelOptions()
    const [showSaveNotice, setShowSaveNotice] = useState(false)
    const [reasonSelections, setReasonSelections] = useState<string[]>([])
    const [otherReasonText, setOtherReasonText] = useState<string>("")

    /** Handles selection for checkbox */
    const handleReasonSelectionChange = useCallback(
        (reason: string): void => {
            const isSelected = reasonSelections.includes(reason)
            if (isSelected) {
                setReasonSelections(
                    reasonSelections.filter((r) => r !== reason),
                )
                return
            }
            setReasonSelections([...reasonSelections, reason])
        },
        [reasonSelections],
    )

    /** Handles text input area */
    const handleOtherReasonTextChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            setOtherReasonText(event.target.value)
        },
        [],
    )

    const cancelMembershipOnSuccess = () => {
        setShowFeedbackForm(false)
        setMembershipCancelled(true)
    }

    return (
        <AlertProvider>
            <div className="MembershipDurationContainer">
                {showFeedbackForm ? (
                    <MembershipFeedbackForm
                        membershipId={membershipId}
                        cancelReasonOptions={feedbackFormOptions}
                        onDontCancelClick={() => setShowFeedbackForm(false)}
                        onCancelSuccess={cancelMembershipOnSuccess}
                        showSaveNotice={() => setShowSaveNotice(true)}
                        handleReasonSelectionChange={
                            handleReasonSelectionChange
                        }
                        handleOtherReasonTextChange={
                            handleOtherReasonTextChange
                        }
                        reasonSelections={reasonSelections}
                        otherReasonText={otherReasonText}
                    />
                ) : (
                    <>
                        {expiredAt && (
                            <MembershipDurationText
                                cancelled={cancelled || membershipCancelled}
                                expiredAt={expiredAt}
                            />
                        )}
                        <MembershipActionContainer
                            cancelled={cancelled || membershipCancelled}
                            onClick={() => {
                                setShowFeedbackForm(true)
                            }}
                        />
                    </>
                )}
                <SaveNotice
                    showSaveNotice={showSaveNotice}
                    showTimeout={3000}
                    text={i18n.thankYouForYourFeedback}
                    className="feedbackSaveNotice"
                />
            </div>
        </AlertProvider>
    )
}
