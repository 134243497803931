import { usernameTitleCase } from "@multimediallc/web-utils"
import { Weights } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { Flex, Link, Typography } from "../../common"
import { ContestLeaderboardRowStats } from "../ContestLeaderboardRowStats"
import type { IContestStat } from "../ContestStats/ContestStats"
import "./ContestLeaderboardRow.scss"

interface ContestLeaderboardRowProps extends IContestStat {
    /** Whether links within each rows should be opened in a new tab */
    openLinksInNewTab: boolean
    /** fires event router loadRoomRequest */
    loadRoomRequestFire?: (event: string) => void
    /** position in the leaderboard */
    position: number
}

export function ContestLeaderboardRow({
    viewers,
    points,
    room_slug,
    room_user,
    image_url,
    position,
    openLinksInNewTab,
    loadRoomRequestFire,
}: ContestLeaderboardRowProps) {
    /** The top row gets a slighty different styling than the other row */
    const determineRowColorClass = (position: number) => {
        return position === 1 ? "topRow" : "otherRows"
    }
    const href = `/${room_slug}/`
    const rowColorClass = determineRowColorClass(position)
    const rowContainerClass = [rowColorClass, "rowContainer"]

    return (
        <>
            <Flex
                direction="row"
                align="center"
                justify="start"
                wrap={false}
                className="ContestLeaderboardRow"
                data-testid="contest-leaderboard-row"
            >
                <Typography
                    weight={Weights.bold}
                    className={mergeClasses(
                        rowContainerClass,
                        "numberContainer",
                    )}
                    data-testid="leaderboard-position"
                >
                    {position}.
                </Typography>
                <Link
                    href={href}
                    rel={openLinksInNewTab ? "noopener noreferrer" : ""}
                    className={mergeClasses(rowContainerClass)}
                    onClick={(e) => {
                        if (!openLinksInNewTab && loadRoomRequestFire) {
                            e.preventDefault()
                            loadRoomRequestFire(room_slug)
                        }
                    }}
                    target={openLinksInNewTab ? "_blank" : ""}
                >
                    <img
                        width={52}
                        height={52}
                        className="roomImage"
                        src={image_url}
                        alt="broadcaster-snapshot"
                        data-testid="room-image"
                    />
                </Link>
                <div
                    className={mergeClasses(rowContainerClass, "metaContainer")}
                >
                    <div
                        className={mergeClasses(rowColorClass, "nameContainer")}
                    >
                        <Typography
                            component={Link}
                            weight={Weights.medium}
                            target={openLinksInNewTab ? "_blank" : ""}
                            rel={openLinksInNewTab ? "noopener noreferrer" : ""}
                            href={href}
                            className={mergeClasses(
                                rowColorClass,
                                "nameElement",
                            )}
                            onClick={(e: Event) => {
                                if (!openLinksInNewTab && loadRoomRequestFire) {
                                    e.preventDefault()
                                    loadRoomRequestFire(room_slug)
                                }
                            }}
                            data-testid="room-text-link"
                        >
                            {usernameTitleCase(room_user)}
                        </Typography>
                    </div>
                    <ContestLeaderboardRowStats
                        viewers={viewers}
                        points={points}
                    />
                </div>
            </Flex>
            <hr className="leaderBoardEndLine" />
        </>
    )
}
