/* eslint-disable @multimediallc/use-custom-console */
/**
 * Wrappers for console functions. `log` is not included by design. In most cases,
 * you either want `debug` or `error`
 */

import * as logging from "@multimediallc/logging"

export const info = logging.info
export const debug = logging.debug

const enum SubSystemType {
    Default = "",
    Video = "VIDEO_",
    PushService = "PUSH_",
}

// specify third param as SubSystemType before exporting
export const error: (
    x: any,
    extra?: any,
    subSystemType?: SubSystemType,
) => void = logging.error

// specify third param as SubSystemType before exporting
export const warn: (
    x: any,
    extra?: any,
    subSystemType?: SubSystemType,
) => void = logging.warn
