import type { ComponentPropsWithoutRef, KeyboardEvent, MouseEvent } from "react"
import { t, Trans } from "@lingui/macro"
import "./TagPagination.scss"
import { Chevron, Line } from "../../../common/atoms/Icons/Others"

interface TagPaginationProps extends ComponentPropsWithoutRef<"div"> {
    isDisabled: boolean
    currentPage: number
    maxPage: number
    onNextPageClick: (e: MouseEvent | KeyboardEvent) => void
    onPrevPageClick: (e: MouseEvent | KeyboardEvent) => void
}

export function TagPagination({
    isDisabled,
    currentPage,
    maxPage,
    onNextPageClick,
    onPrevPageClick,
    ...props
}: TagPaginationProps) {
    const handlePrevKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onPrevPageClick(event)
        }
    }

    const handleNextKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
            onNextPageClick(event)
        }
    }

    return (
        <div
            {...props}
            className={`TagPagination${
                isDisabled ? " TagPagination--disabled" : ""
            }`}
        >
            <div
                className="TagPagination__left-arrow"
                tabIndex={0}
                onClick={onPrevPageClick}
                onKeyDown={handlePrevKeyPress}
            >
                <Chevron className="TagPagination__arrow-icon" />
                <Line className="TagPagination__chevron-underline" />
            </div>

            <div className="TagPagination__paging-display wide">
                <Trans>
                    <div className="TagPagination__paging-text">
                        {currentPage}
                    </div>{" "}
                    <div className="TagPagination__paging-text">of</div>{" "}
                    <div className="TagPagination__paging-text">{maxPage}</div>
                </Trans>
            </div>
            <div className="TagPagination__paging-display narrow">
                <div className="TagPagination__paging-text">
                    {`${currentPage} / ${maxPage}`}
                </div>
            </div>
            <div
                className="TagPagination__page-button"
                onClick={onNextPageClick}
                onKeyDown={handleNextKeyPress}
                title={t`next`}
            >
                <div
                    className="TagPagination__paging-text TagPagination__paging-text--next"
                    tabIndex={0}
                >
                    {t`next`}
                </div>
                <Chevron className="TagPagination__arrow-icon TagPagination__arrow-icon--next" />
                <Line className="TagPagination__chevron-underline" />
            </div>
        </div>
    )
}
