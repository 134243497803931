import { ArgJSONMap } from "@multimediallc/web-utils"
import { generateApiPath } from "../../../routes/util"
import { fetchPost } from "../../../utils/myFetch"

export interface ISendReportResponse {
    success: boolean
    html?: string
}

export const sendReport = (
    languageCode: string,
    roomName: string,
    category: string,
    comment: string,
): Promise<ISendReportResponse> => {
    const dataObject = {
        category: category,
        additional_comments: comment,
    }
    return fetchPost(
        generateApiPath(languageCode, "abuse", "report/:roomName/", {
            roomName,
        }),
        dataObject,
    ).then((resp) => {
        let result
        try {
            result = resp.jsonData
        } catch {
            result = resp
        }
        return parseAbuseReportResult(result as object)
    })
}

const parseAbuseReportResult = (response: object): ISendReportResponse => {
    const p = new ArgJSONMap(response)
    const reportResponse: ISendReportResponse = {
        success: p.getString("result", false) === "success",
        html: p.getStringOrUndefined("html", false),
    }
    p.logUnusedDebugging("parseAbuseReportResult")
    return reportResponse
}
