import type React from "react"
import "./HomepageFiltersIcon.scss"
import {
    HomepageFiltersAppliedIcon,
    HomepageFiltersUnappliedIcon,
} from "../../../common/atoms/Icons/Others"

interface HomepageFiltersIconProps
    extends React.ComponentPropsWithoutRef<"div"> {
    hasSelectedFilters: boolean
}

export function HomepageFiltersIcon({
    hasSelectedFilters,
    ...props
}: HomepageFiltersIconProps) {
    const icon = hasSelectedFilters ? (
        <HomepageFiltersAppliedIcon
            className={`HomepageFiltersIcon__svg${hasSelectedFilters ? "--active" : ""}`}
        />
    ) : (
        <HomepageFiltersUnappliedIcon />
    )
    return (
        <div
            {...props}
            className={`HomepageFiltersIcon${
                hasSelectedFilters ? "--active" : ""
            }`}
        >
            {icon}
        </div>
    )
}
