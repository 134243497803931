import React, { useEffect, useState } from "react"
import "./SaveNotice.scss"
import { Sizes } from "../../../interfaces/sizing"
import { mergeClasses } from "../../../utils/css"
import { Typography } from "../../common"

interface SaveNoticeProps {
    /** Whether to show the save notice */
    showSaveNotice: boolean
    /** The text that we want the notice to have */
    text: string
    /** How long should the notice be shown (in ms) */
    showTimeout: number
    /** Any extra classes you might need to provide. Space delimited. */
    className?: string
}

/** A notice that fades in for a certain amount of time, then fades out*/
export function SaveNotice({
    showSaveNotice,
    text,
    showTimeout,
    className,
}: SaveNoticeProps) {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        if (showSaveNotice) {
            setIsVisible(true)
            timeoutId = setTimeout(() => {
                setIsVisible(false)
            }, showTimeout)
        } else {
            setIsVisible(false)
        }

        return () => {
            clearTimeout(timeoutId)
        }
    }, [showSaveNotice, showTimeout])

    return (
        <Typography
            size={Sizes.xs}
            className={`${mergeClasses(className, "SaveNotice")} ${
                isVisible ? "visible" : ""
            }`}
        >
            {text}
        </Typography>
    )
}
