import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgSearch = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#0a5a83"
            fillRule="evenodd"
            d="M7.93 9.006A5 5 0 0 1 5 9.95c-2.761 0-5-2.228-5-4.975C0 2.227 2.239 0 5 0s5 2.227 5 4.975c0 1.12-.372 2.153-1 2.985l3 2.985L10.94 12zm.57-4.031A3.49 3.49 0 0 1 5 8.457c-1.933 0-3.5-1.559-3.5-3.482A3.49 3.49 0 0 1 5 1.492c1.933 0 3.5 1.56 3.5 3.483"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgSearch)
const Memo = memo(ForwardRef)
export default Memo
