import { gettext } from "@multimediallc/web-utils"
import { Sizes } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import "./ContestLeaderboardRowStats.scss"

interface ContestLeaderboardRowStatsProps {
    /** Number of viewers for the broadcaster in the leaderboard */
    viewers: number
    /** Number of points for the broadcaster in the leaderboard */
    points: number
}

const i18n = {
    registeredViewersWatchingText: gettext(
        "Unique Registered Viewers Watching",
    ),
    pointsText: gettext("Points"),
}

export function ContestLeaderboardRowStats({
    viewers,
    points,
}: ContestLeaderboardRowStatsProps) {
    return (
        <div className="ContestLeaderboardRowStats">
            <Typography
                size={Sizes.xs}
                component="span"
                className="statElement"
                data-testid="registered-viewers-watching-label"
            >
                {i18n.registeredViewersWatchingText}:
                <span
                    className="statData"
                    data-testid="registered-viewers-watching-value"
                >
                    {viewers}
                </span>
            </Typography>
            <Typography
                size={Sizes.xs}
                component="span"
                className="statElement"
                data-testid="points-label"
            >
                {i18n.pointsText}:
                <span className="statData" data-testid="points-value">
                    {points}
                </span>
            </Typography>
        </div>
    )
}
