// This is meant to be instantiated once on any page that plans to use GenderIcon (Female, Couple, etc.), since they refer to the defs provided here.
export default function GenderIconDefs() {
    return (
        <svg height={0} width={0}>
            <defs>
                <g id="FemaleIconG" mask="url(#female_svg__a)">
                    <path
                        fill="url(#female_svg__b)"
                        d="M10.819 11.726H1.27a.506.506 0 0 1-.529-.472V9.878c0-.26.252-.29.53-.473l4.773-2.846 4.774 2.85c.31.195.528.214.528.472v1.377a.506.506 0 0 1-.528.468"
                    />
                    <path
                        fill="url(#female_svg__c)"
                        d="M5.28 6.409h1.59v2.355H5.28z"
                    />
                    <path
                        fill="url(#female_svg__d)"
                        d="M6.195.191c1.988 0 3.161 2.854 2.963 6.567-2.363.168-6.162.135-6.162.135S2.483.19 6.196.19"
                    />
                    <path
                        fill="#4B201C"
                        fillOpacity={0.34}
                        d="M6.045 7.155c1.035 0 1.875-1.459 1.875-3.259S7.08.637 6.045.637 4.17 2.097 4.17 3.897s.84 3.258 1.875 3.258"
                    />
                    <path
                        fill="url(#female_svg__e)"
                        d="M6.143 7.155c1.035 0 1.875-1.459 1.875-3.259S7.178.637 6.143.637s-1.875 1.46-1.875 3.26.839 3.258 1.875 3.258"
                    />
                    <path
                        fill="url(#female_svg__f)"
                        d="M6.199.191c-1.875 0-2.674 1.74-3 3.447l1.065.337A5.8 5.8 0 0 1 6.195 2.88c1.125-.375.971-1.192.971-1.192a1.76 1.76 0 0 1 .791 1.368c.064.548.27.173.75-.319C8.235 1.177 7.357.192 6.2.192"
                    />
                    <ellipse
                        cx={6.025}
                        cy={4.5}
                        fill="url(#female_svg__g)"
                        rx={2.375}
                        ry={4}
                    />
                    <path
                        fill="#E1C3C2"
                        d="M6.874 11.34V7.391l3.847 2.082c.086.052.169.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.121-.058.236-.128l3.919-2.111v3.986z"
                    />
                    <path
                        fill="url(#female_svg__h)"
                        d="M6.874 11.34V7.391l3.847 2.082c.087.052.17.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.122-.058.236-.128l3.92-2.111v3.986z"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M5 7.416a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M5.296 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52M7.17 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M7.467 7.42a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="url(#female_svg__i)"
                        d="M5.28 8.764h1.59v2.962H5.28z"
                    />
                </g>
                <mask
                    id="female_svg__a"
                    width={12}
                    height={12}
                    x={0}
                    y={0}
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M0 0h12v12H0z" />
                </mask>
                <linearGradient
                    id="female_svg__b"
                    x1={6.045}
                    x2={6.045}
                    y1={6.559}
                    y2={11.727}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ECCBCF" />
                    <stop offset={1} stopColor="#AF3241" />
                </linearGradient>
                <linearGradient
                    id="female_svg__c"
                    x1={5.28}
                    x2={6.874}
                    y1={7.586}
                    y2={7.586}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A57C4B" />
                    <stop offset={0.07} stopColor="#BE9461" />
                    <stop offset={0.14} stopColor="#D2A773" />
                    <stop offset={0.22} stopColor="#E0B480" />
                    <stop offset={0.32} stopColor="#E9BC87" />
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.68} stopColor="#E9BC87" />
                    <stop offset={0.78} stopColor="#E1B480" />
                    <stop offset={0.86} stopColor="#D3A874" />
                    <stop offset={0.93} stopColor="#C09663" />
                    <stop offset={0.99} stopColor="#A87F4D" />
                    <stop offset={1} stopColor="#A37A49" />
                </linearGradient>
                <linearGradient
                    id="female_svg__d"
                    x1={6.079}
                    x2={6.079}
                    y1={0.191}
                    y2={6.893}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.56} stopColor="#6E3B35" />
                    <stop offset={0.72} stopColor="#6A3430" />
                    <stop offset={0.95} stopColor="#5D2221" />
                    <stop offset={1} stopColor="#5A1D1D" />
                </linearGradient>
                <linearGradient
                    id="female_svg__f"
                    x1={5.966}
                    x2={5.966}
                    y1={0.191}
                    y2={3.975}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CF8F5E" />
                    <stop offset={0.04} stopColor="#C6875A" />
                    <stop offset={0.2} stopColor="#A66C4D" />
                    <stop offset={0.37} stopColor="#8D5642" />
                    <stop offset={0.55} stopColor="#7C473B" />
                    <stop offset={0.73} stopColor="#713E36" />
                    <stop offset={0.95} stopColor="#6E3B35" />
                </linearGradient>
                <linearGradient
                    id="female_svg__h"
                    x1={6.045}
                    x2={6.045}
                    y1={7.354}
                    y2={11.345}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF3241" />
                    <stop offset={1} stopColor="#ECCBCF" />
                </linearGradient>
                <linearGradient
                    id="female_svg__i"
                    x1={6.079}
                    x2={6.079}
                    y1={11.726}
                    y2={8.764}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9D9FA2" />
                    <stop offset={0.49} stopColor="#fff" />
                    <stop offset={0.68} stopColor="#FDFDFD" />
                    <stop offset={0.77} stopColor="#F5F5F6" />
                    <stop offset={0.85} stopColor="#E7E8E9" />
                    <stop offset={0.92} stopColor="#D4D5D7" />
                    <stop offset={0.97} stopColor="#BCBEC0" />
                    <stop offset={1} stopColor="#AEB0B3" />
                </linearGradient>
                <radialGradient
                    id="female_svg__e"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.87125 0 0 3.23726 6.143 3.918)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.66} stopColor="#E9BC87" />
                    <stop offset={0.75} stopColor="#E1B480" />
                    <stop offset={0.82} stopColor="#D3A874" />
                    <stop offset={0.88} stopColor="#C09663" />
                    <stop offset={0.94} stopColor="#A87F4D" />
                    <stop offset={0.95} stopColor="#A37A49" />
                    <stop offset={0.99} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="female_svg__g"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(2 -1 .59375 1.1875 5.5 1.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2AB8D" />
                    <stop offset={0.703} stopColor="#A66C4E" stopOpacity={0} />
                </radialGradient>
                <g id="CoupleIconG" mask="url(#couple_svg__i)">
                    <path
                        fill="url(#couple_svg__j)"
                        d="M7.24 11.817H.85a.34.34 0 0 1-.353-.316v-.921c0-.174.168-.194.354-.316l3.194-1.905 3.195 1.907c.208.13.353.143.353.316v.921a.34.34 0 0 1-.353.314"
                    />
                    <path
                        fill="url(#couple_svg__k)"
                        d="M3.533 8.258h1.064v1.576H3.533z"
                    />
                    <path
                        fill="url(#couple_svg__l)"
                        d="M4.145 4.098c1.33 0 2.116 1.91 1.983 4.394-1.581.113-4.123.09-4.123.09s-.344-4.484 2.14-4.484"
                    />
                    <path
                        fill="#4B201C"
                        fillOpacity={0.34}
                        d="M4.045 8.758c.693 0 1.255-.976 1.255-2.18s-.562-2.182-1.255-2.182-1.254.977-1.254 2.181.561 2.18 1.254 2.18"
                    />
                    <path
                        fill="url(#couple_svg__m)"
                        d="M4.11 8.758c.693 0 1.255-.976 1.255-2.18S4.803 4.395 4.11 4.395s-1.254.977-1.254 2.181.561 2.18 1.254 2.18"
                    />
                    <path
                        fill="url(#couple_svg__n)"
                        d="M4.148 4.098c-1.255 0-1.79 1.164-2.008 2.306l.713.226a3.9 3.9 0 0 1 1.292-.733c.753-.25.65-.798.65-.798a1.18 1.18 0 0 1 .53.916c.042.366.18.115.502-.213-.316-1.044-.904-1.704-1.68-1.704"
                    />
                    <ellipse
                        cx={4.032}
                        cy={6.981}
                        fill="url(#couple_svg__o)"
                        rx={1.589}
                        ry={2.677}
                    />
                    <path
                        fill="#E1C3C2"
                        d="M4.6 11.558V8.916l2.574 1.393q.088.051.16.085c.131.065.131.073.131.11v.879a.21.21 0 0 1-.225.178H4.6zm-3.75 0a.21.21 0 0 1-.225-.178v-.878c0-.038 0-.05.138-.113q.081-.039.158-.085L3.543 8.89v2.667z"
                    />
                    <path
                        fill="url(#couple_svg__p)"
                        d="M4.6 11.558V8.916l2.574 1.393q.088.051.16.085c.131.065.131.073.131.11v.879a.21.21 0 0 1-.225.178H4.6zm-3.75 0a.21.21 0 0 1-.225-.178v-.878c0-.038 0-.05.138-.113q.081-.039.158-.085L3.543 8.89v2.667z"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M3.346 8.932a.2.2 0 1 0-.2-.347.2.2 0 0 0 .2.347"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M3.544 9.279a.2.2 0 1 0-.2-.348.2.2 0 0 0 .2.348M4.799 9.279a.2.2 0 1 0-.201-.348.2.2 0 0 0 .2.348"
                    />
                    <path
                        fill="#DA1C5C"
                        d="M4.997 8.935a.2.2 0 1 0-.201-.348.2.2 0 0 0 .2.348"
                    />
                    <path
                        fill="url(#couple_svg__q)"
                        d="M3.533 9.834h1.064v1.983H3.533z"
                    />
                </g>
                <mask
                    id="couple_svg__i"
                    width={9}
                    height={9}
                    x={0}
                    y={3}
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M0 3.97h8.03V12H0z" />
                </mask>
                <linearGradient
                    id="couple_svg__a"
                    x1={7.987}
                    x2={7.987}
                    y1={4.098}
                    y2={7.994}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AAD1F6" />
                    <stop offset={0.486} stopColor="#82AAD7" />
                    <stop offset={1} stopColor="#57789D" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__c"
                    x1={7.985}
                    x2={7.985}
                    y1={4.684}
                    y2={7.695}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.028} stopColor="#57789D" />
                    <stop offset={0.486} stopColor="#82AAD7" />
                    <stop offset={1} stopColor="#AAD1F6" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__d"
                    x1={8.03}
                    x2={8.03}
                    y1={7.89}
                    y2={5.747}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9D9FA2" />
                    <stop offset={0.49} stopColor="#fff" />
                    <stop offset={0.68} stopColor="#FDFDFD" />
                    <stop offset={0.77} stopColor="#F5F5F6" />
                    <stop offset={0.85} stopColor="#E7E8E9" />
                    <stop offset={0.92} stopColor="#D4D5D7" />
                    <stop offset={0.97} stopColor="#BCBEC0" />
                    <stop offset={1} stopColor="#AEB0B3" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__e"
                    x1={7.036}
                    x2={9.024}
                    y1={4.8}
                    y2={4.8}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A57C4B" />
                    <stop offset={0.07} stopColor="#BE9461" />
                    <stop offset={0.14} stopColor="#D2A773" />
                    <stop offset={0.22} stopColor="#E0B480" />
                    <stop offset={0.32} stopColor="#E9BC87" />
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.68} stopColor="#E9BC87" />
                    <stop offset={0.78} stopColor="#E1B480" />
                    <stop offset={0.86} stopColor="#D3A874" />
                    <stop offset={0.93} stopColor="#C09663" />
                    <stop offset={0.99} stopColor="#A87F4D" />
                    <stop offset={1} stopColor="#A37A49" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__j"
                    x1={4.045}
                    x2={4.045}
                    y1={8.359}
                    y2={11.817}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ECCBCF" />
                    <stop offset={1} stopColor="#AF3241" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__k"
                    x1={3.533}
                    x2={4.6}
                    y1={9.046}
                    y2={9.046}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A57C4B" />
                    <stop offset={0.07} stopColor="#BE9461" />
                    <stop offset={0.14} stopColor="#D2A773" />
                    <stop offset={0.22} stopColor="#E0B480" />
                    <stop offset={0.32} stopColor="#E9BC87" />
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.68} stopColor="#E9BC87" />
                    <stop offset={0.78} stopColor="#E1B480" />
                    <stop offset={0.86} stopColor="#D3A874" />
                    <stop offset={0.93} stopColor="#C09663" />
                    <stop offset={0.99} stopColor="#A87F4D" />
                    <stop offset={1} stopColor="#A37A49" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__l"
                    x1={4.068}
                    x2={4.068}
                    y1={4.098}
                    y2={8.582}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.56} stopColor="#6E3B35" />
                    <stop offset={0.72} stopColor="#6A3430" />
                    <stop offset={0.95} stopColor="#5D2221" />
                    <stop offset={1} stopColor="#5A1D1D" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__n"
                    x1={3.992}
                    x2={3.992}
                    y1={4.098}
                    y2={6.63}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CF8F5E" />
                    <stop offset={0.04} stopColor="#C6875A" />
                    <stop offset={0.2} stopColor="#A66C4D" />
                    <stop offset={0.37} stopColor="#8D5642" />
                    <stop offset={0.55} stopColor="#7C473B" />
                    <stop offset={0.73} stopColor="#713E36" />
                    <stop offset={0.95} stopColor="#6E3B35" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__p"
                    x1={4.045}
                    x2={4.045}
                    y1={8.891}
                    y2={11.562}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AF3241" />
                    <stop offset={1} stopColor="#ECCBCF" />
                </linearGradient>
                <linearGradient
                    id="couple_svg__q"
                    x1={4.068}
                    x2={4.068}
                    y1={11.817}
                    y2={9.834}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9D9FA2" />
                    <stop offset={0.49} stopColor="#fff" />
                    <stop offset={0.68} stopColor="#FDFDFD" />
                    <stop offset={0.77} stopColor="#F5F5F6" />
                    <stop offset={0.85} stopColor="#E7E8E9" />
                    <stop offset={0.92} stopColor="#D4D5D7" />
                    <stop offset={0.97} stopColor="#BCBEC0" />
                    <stop offset={1} stopColor="#AEB0B3" />
                </linearGradient>
                <radialGradient
                    id="couple_svg__b"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(8.03 6.168)scale(2.80802)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7FC8E7" />
                    <stop offset={1} stopColor="#6DCFF6" />
                </radialGradient>
                <radialGradient
                    id="couple_svg__f"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.77776 0 0 2.6167 8.008 2.458)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.71} stopColor="#B49167" />
                    <stop offset={0.82} stopColor="#B08C60" />
                    <stop offset={0.96} stopColor="#A67E4E" />
                    <stop offset={0.99} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="couple_svg__g"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.77776 0 0 2.6167 8.008 2.458)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EDBE89" />
                    <stop offset={0.569} stopColor="#EDBE89" />
                    <stop offset={0.952} stopColor="#A67E4E" />
                    <stop offset={1} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="couple_svg__h"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(0 .33459 -.95148 0 7.985 .67)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4C4C4" />
                    <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="couple_svg__m"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.25219 0 0 2.16629 4.11 6.592)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.66} stopColor="#E9BC87" />
                    <stop offset={0.75} stopColor="#E1B480" />
                    <stop offset={0.82} stopColor="#D3A874" />
                    <stop offset={0.88} stopColor="#C09663" />
                    <stop offset={0.94} stopColor="#A87F4D" />
                    <stop offset={0.95} stopColor="#A37A49" />
                    <stop offset={0.99} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="couple_svg__o"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.33835 -.66917 .39732 .79464 3.68 4.974)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2AB8D" />
                    <stop offset={0.703} stopColor="#A66C4E" stopOpacity={0} />
                </radialGradient>
                <g id="TransIconG" mask="url(#trans_svg__a)">
                    <path
                        fill="url(#trans_svg__b)"
                        d="M10.819 11.726H1.27a.506.506 0 0 1-.529-.472V9.878c0-.26.252-.29.53-.473l4.773-2.846 4.774 2.85c.31.195.528.214.528.472v1.377a.506.506 0 0 1-.528.468"
                    />
                    <path
                        fill="url(#trans_svg__c)"
                        d="M5.28 6.409h1.59v2.355H5.28z"
                    />
                    <path
                        fill="url(#trans_svg__d)"
                        d="M6.195.191c1.988 0 3.161 2.854 2.963 6.567-2.363.168-6.162.135-6.162.135S2.483.19 6.196.19"
                    />
                    <path
                        fill="#4B201C"
                        fillOpacity={0.34}
                        d="M6.045 7.155c1.035 0 1.875-1.459 1.875-3.259S7.08.637 6.045.637 4.17 2.097 4.17 3.897s.84 3.258 1.875 3.258"
                    />
                    <path
                        fill="url(#trans_svg__e)"
                        d="M6.143 7.155c1.035 0 1.875-1.459 1.875-3.259S7.178.637 6.143.637s-1.875 1.46-1.875 3.26.839 3.258 1.875 3.258"
                    />
                    <path
                        fill="url(#trans_svg__f)"
                        d="M6.199.191c-1.875 0-2.674 1.74-3 3.447l1.065.337A5.8 5.8 0 0 1 6.195 2.88c1.125-.375.971-1.192.971-1.192a1.76 1.76 0 0 1 .791 1.368c.064.548.27.173.75-.319C8.235 1.177 7.357.192 6.2.192"
                    />
                    <ellipse
                        cx={6.025}
                        cy={4.5}
                        fill="url(#trans_svg__g)"
                        rx={2.375}
                        ry={4}
                    />
                    <path
                        fill="#E1C3C2"
                        d="M6.874 11.34V7.391l3.847 2.082c.086.052.169.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.121-.058.236-.128l3.919-2.111v3.986z"
                    />
                    <path
                        fill="url(#trans_svg__h)"
                        d="M6.874 11.34V7.391l3.847 2.082c.087.052.17.093.24.127.195.098.195.109.195.165v1.313a.31.31 0 0 1-.337.266H6.874zm-5.603 0a.31.31 0 0 1-.337-.266V9.76c0-.056 0-.075.206-.168q.122-.058.236-.128l3.92-2.111v3.986z"
                    />
                    <path
                        fill="#9D4633"
                        d="M5 7.416a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="#9D4633"
                        d="M5.296 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52M7.17 7.934a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="#9D4633"
                        d="M7.467 7.42a.3.3 0 1 0-.3-.52.3.3 0 0 0 .3.52"
                    />
                    <path
                        fill="url(#trans_svg__i)"
                        d="M5.28 8.764h1.59v2.962H5.28z"
                    />
                </g>
                <mask
                    id="trans_svg__a"
                    width={12}
                    height={12}
                    x={0}
                    y={0}
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                >
                    <path fill="#fff" d="M0 0h12v12H0z" />
                </mask>
                <linearGradient
                    id="trans_svg__b"
                    x1={6.045}
                    x2={6.045}
                    y1={6.559}
                    y2={11.727}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C7E6D3" />
                    <stop offset={1} stopColor="#258B59" />
                </linearGradient>
                <linearGradient
                    id="trans_svg__c"
                    x1={5.28}
                    x2={6.874}
                    y1={7.586}
                    y2={7.586}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A57C4B" />
                    <stop offset={0.07} stopColor="#BE9461" />
                    <stop offset={0.14} stopColor="#D2A773" />
                    <stop offset={0.22} stopColor="#E0B480" />
                    <stop offset={0.32} stopColor="#E9BC87" />
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.68} stopColor="#E9BC87" />
                    <stop offset={0.78} stopColor="#E1B480" />
                    <stop offset={0.86} stopColor="#D3A874" />
                    <stop offset={0.93} stopColor="#C09663" />
                    <stop offset={0.99} stopColor="#A87F4D" />
                    <stop offset={1} stopColor="#A37A49" />
                </linearGradient>
                <linearGradient
                    id="trans_svg__d"
                    x1={6.079}
                    x2={6.079}
                    y1={0.191}
                    y2={6.893}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.56} stopColor="#6E3B35" />
                    <stop offset={0.72} stopColor="#6A3430" />
                    <stop offset={0.95} stopColor="#5D2221" />
                    <stop offset={1} stopColor="#5A1D1D" />
                </linearGradient>
                <linearGradient
                    id="trans_svg__f"
                    x1={5.966}
                    x2={5.966}
                    y1={0.191}
                    y2={3.975}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CF8F5E" />
                    <stop offset={0.04} stopColor="#C6875A" />
                    <stop offset={0.2} stopColor="#A66C4D" />
                    <stop offset={0.37} stopColor="#8D5642" />
                    <stop offset={0.55} stopColor="#7C473B" />
                    <stop offset={0.73} stopColor="#713E36" />
                    <stop offset={0.95} stopColor="#6E3B35" />
                </linearGradient>
                <linearGradient
                    id="trans_svg__h"
                    x1={6.045}
                    x2={6.045}
                    y1={7.354}
                    y2={11.345}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#258B59" />
                    <stop offset={1} stopColor="#C7E6D3" />
                </linearGradient>
                <linearGradient
                    id="trans_svg__i"
                    x1={6.079}
                    x2={6.079}
                    y1={11.726}
                    y2={8.764}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9D9FA2" />
                    <stop offset={0.49} stopColor="#fff" />
                    <stop offset={0.68} stopColor="#FDFDFD" />
                    <stop offset={0.77} stopColor="#F5F5F6" />
                    <stop offset={0.85} stopColor="#E7E8E9" />
                    <stop offset={0.92} stopColor="#D4D5D7" />
                    <stop offset={0.97} stopColor="#BCBEC0" />
                    <stop offset={1} stopColor="#AEB0B3" />
                </linearGradient>
                <radialGradient
                    id="trans_svg__e"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(1.87125 0 0 3.23726 6.143 3.918)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.66} stopColor="#E9BC87" />
                    <stop offset={0.75} stopColor="#E1B480" />
                    <stop offset={0.82} stopColor="#D3A874" />
                    <stop offset={0.88} stopColor="#C09663" />
                    <stop offset={0.94} stopColor="#A87F4D" />
                    <stop offset={0.95} stopColor="#A37A49" />
                    <stop offset={0.99} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="trans_svg__g"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(2 -1 .59375 1.1875 5.5 1.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2AB8D" />
                    <stop offset={0.703} stopColor="#A66C4E" stopOpacity={0} />
                </radialGradient>
                <radialGradient
                    id="male_svg__b"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(6.067 9.217)scale(4.19625)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#7FC8E7" />
                    <stop offset={1} stopColor="#6DCFF6" />
                </radialGradient>
                <radialGradient
                    id="male_svg__f"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(2.65665 0 0 3.91035 6.035 3.674)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.71} stopColor="#B49167" />
                    <stop offset={0.82} stopColor="#B08C60" />
                    <stop offset={0.96} stopColor="#A67E4E" />
                    <stop offset={0.99} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="male_svg__g"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(2.65665 0 0 3.91035 6.035 3.674)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EDBE89" />
                    <stop offset={0.569} stopColor="#EDBE89" />
                    <stop offset={0.952} stopColor="#A67E4E" />
                    <stop offset={1} stopColor="#A37A49" />
                </radialGradient>
                <radialGradient
                    id="male_svg__h"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(0 .5 -1.42187 0 6 1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4C4C4" />
                    <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                </radialGradient>
                <linearGradient
                    id="male_svg__a"
                    x1={6.003}
                    x2={6.003}
                    y1={6.123}
                    y2={11.946}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AAD1F6" />
                    <stop offset={0.486} stopColor="#82AAD7" />
                    <stop offset={1} stopColor="#57789D" />
                </linearGradient>
                <linearGradient
                    id="male_svg__c"
                    x1={6}
                    x2={6}
                    y1={7}
                    y2={11.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.028} stopColor="#57789D" />
                    <stop offset={0.486} stopColor="#82AAD7" />
                    <stop offset={1} stopColor="#AAD1F6" />
                </linearGradient>
                <linearGradient
                    id="male_svg__d"
                    x1={6.067}
                    x2={6.067}
                    y1={11.79}
                    y2={8.587}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9D9FA2" />
                    <stop offset={0.49} stopColor="#fff" />
                    <stop offset={0.68} stopColor="#FDFDFD" />
                    <stop offset={0.77} stopColor="#F5F5F6" />
                    <stop offset={0.85} stopColor="#E7E8E9" />
                    <stop offset={0.92} stopColor="#D4D5D7" />
                    <stop offset={0.97} stopColor="#BCBEC0" />
                    <stop offset={1} stopColor="#AEB0B3" />
                </linearGradient>
                <linearGradient
                    id="male_svg__e"
                    x1={4.583}
                    x2={7.553}
                    y1={7.174}
                    y2={7.174}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A57C4B" />
                    <stop offset={0.07} stopColor="#BE9461" />
                    <stop offset={0.14} stopColor="#D2A773" />
                    <stop offset={0.22} stopColor="#E0B480" />
                    <stop offset={0.32} stopColor="#E9BC87" />
                    <stop offset={0.49} stopColor="#EBBE89" />
                    <stop offset={0.68} stopColor="#E9BC87" />
                    <stop offset={0.78} stopColor="#E1B480" />
                    <stop offset={0.86} stopColor="#D3A874" />
                    <stop offset={0.93} stopColor="#C09663" />
                    <stop offset={0.99} stopColor="#A87F4D" />
                    <stop offset={1} stopColor="#A37A49" />
                </linearGradient>
            </defs>
        </svg>
    )
}
