import "./LanguageFilterSearchAutocomplete.scss"
import { gettext } from "@multimediallc/web-utils"
import { Sizes } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import type { ILanguage } from "../LanguageFilterSection"

interface LanguageFilterSearchAutocompleteProps {
    items: ILanguage[]
    selectedItemIndex?: number
    setSelectedItemIndex: (index: number | undefined) => void
    onLanguageClick: (value: string) => void
}

const i18n = {
    notFound: gettext("Not Found"),
}

export function LanguageFilterSearchAutocomplete({
    items,
    selectedItemIndex,
    setSelectedItemIndex,
    onLanguageClick,
}: LanguageFilterSearchAutocompleteProps) {
    return (
        <div className="LanguageFilterSearchAutocomplete">
            {items.length > 0 ? (
                <>
                    {items.map((item, index) => (
                        <div
                            className={`autocompleteItem ${selectedItemIndex === index ? "selected" : ""}`}
                            onMouseEnter={() => setSelectedItemIndex(index)}
                            onMouseLeave={() => setSelectedItemIndex(undefined)}
                            onMouseDown={() => onLanguageClick(item.code)}
                            key={item.code}
                        >
                            {item.display_name}
                        </div>
                    ))}
                </>
            ) : (
                <Typography
                    className="notFoundText"
                    component="div"
                    size={Sizes.xs}
                >
                    {i18n.notFound}
                </Typography>
            )}
        </div>
    )
}
