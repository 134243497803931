import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgRemoveFilterIcon = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path d="M6.767 6.063a.083.083 0 0 1 0-.118l3.087-3.088a.5.5 0 0 0-.708-.707L6.06 5.236a.083.083 0 0 1-.118 0L2.854 2.15a.5.5 0 1 0-.708.707l3.087 3.088a.083.083 0 0 1 0 .118L2.146 9.15a.5.5 0 1 0 .708.707L5.94 6.77a.084.084 0 0 1 .118 0l3.087 3.087a.5.5 0 1 0 .708-.707z" />
    </svg>
)
const ForwardRef = forwardRef(SvgRemoveFilterIcon)
const Memo = memo(ForwardRef)
export default Memo
