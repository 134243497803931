import type React from "react"
import { useRef, useState } from "react"
import { useFeatureFlag } from "../../../hooks/appContext"
import { colorClass } from "../../../utils/css"
import "./RoomSubject.scss"
import { PrivateShowBadge } from "../PrivateShowBadge"
import { RoomSubjectSpan } from "../RoomSubjectSpan"

type RoomSubjectProps = {
    /**
     *  The Text comprising the room subject. Will be parsed out and approved hashtags turned into links.
     **/
    text: string
    /**
     * Width of the hover tooltip to display. Will _only_ appear if the full title is too wide
     * to be fully visible (based on the height growing when text is forced to the next line.)
     */
    toolTipWidth: number
    privateShowBroadcasting?: boolean
    roomName?: string
    onHashtagClick?: (ev: React.MouseEvent) => void
}

export function RoomSubject(props: RoomSubjectProps) {
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const roomSubjectRef = useRef<HTMLSpanElement>(null)
    const isPrivateShowRequestUXActive = useFeatureFlag(
        "SplitModePrivateShowUX",
    )

    const onmouseenter = () => {
        if (
            roomSubjectRef.current?.clientWidth !==
            roomSubjectRef.current?.scrollWidth
        ) {
            setTooltipVisible(true)
        }
    }

    const onmouseleave = () => {
        setTooltipVisible(false)
    }

    const defaultRoomSubject =
        props.roomName !== undefined &&
        props.roomName !== "" &&
        props.privateShowBroadcasting !== undefined &&
        props.privateShowBroadcasting
            ? `${props.roomName}'s room`
            : props.text

    return (
        <div
            onMouseEnter={onmouseenter}
            onMouseLeave={onmouseleave}
            className={`${
                isPrivateShowRequestUXActive.active
                    ? "privateShowBadgeHolder"
                    : ""
            }`}
        >
            {isPrivateShowRequestUXActive.active &&
                props.privateShowBroadcasting !== undefined &&
                props.privateShowBroadcasting && <PrivateShowBadge />}
            <div
                className={`roomSubject ${colorClass.defaultColor}`}
                data-testid="room-subject"
            >
                <RoomSubjectSpan
                    ref={roomSubjectRef}
                    text={defaultRoomSubject}
                    onHashtagClick={props.onHashtagClick}
                />
            </div>
            <div
                className={`roomSubjectTooltip ${colorClass.roomSubjectToolTipColor}`}
                style={{
                    maxWidth: `${props.toolTipWidth}px`,
                    minWidth: `${roomSubjectRef.current?.clientWidth}px`,
                    visibility: tooltipVisible ? "visible" : "hidden",
                }}
                data-testid="room-subject-tooltip"
            >
                <RoomSubjectSpan text={props.text} />
            </div>
        </div>
    )
}
