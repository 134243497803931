import { error } from "../debug"

// makeAPIRegistry adds API functions to a window regsitry for use outside of React.
// Use this function when defining API functions to be used in TypeScript code.
// Note:  This function should only ever be called once per app, with all API functions to be exported passed in.
export function makeAPIRegistry(apiFuncs: {
    [key: string]: (...attributes: any) => Promise<any>
}): void {
    const global = window as any
    if (global["ReactAPIRegistry"] !== undefined) {
        error(
            "ReactAPIRegistry error:  Attempted to makeAPIRegistry when registry already exists.",
        )
    }
    global["ReactAPIRegistry"] = {}
    Object.entries(apiFuncs).forEach(([name, apiFunc]) => {
        global["ReactAPIRegistry"][name] = apiFunc
    })
}
