// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../static/send-button-grey.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../static/send-button-orange.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendButton{max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.SendButton.Button{font-family:"UbuntuRegular",Arial,Helvetica,sans-serif}.SendButton.ButtonSize-small{height:initial;line-height:initial}.SendButton.ButtonColor-red,.SendButton.ButtonColor-clear{border:none}.SendButton.hidden{display:none}.SendButton.SplitMode{text-shadow:#bc2b1d 1px 1px 0}.SendButton.SplitMode.ButtonSize-small{padding:3px 5px}.SendButton.DraggableCanvas.ButtonSize-small{padding:5px 8px 4px}.SendButton.MobileSplitMode{width:40px;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;vertical-align:middle}.SendButton.MobileSplitMode.ButtonSize-small{height:40px;padding:0}.SendButton.MobileSplitMode.highlight{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
