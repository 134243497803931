import { useEffect, useState } from "react"
import "./FlagIcon.scss"
import { error } from "@multimediallc/logging"

interface FlagIconProps extends React.ComponentPropsWithoutRef<"span"> {
    countryName: string
    countryCode: string
    square?: boolean
}

export function FlagIcon({
    countryName,
    countryCode,
    square = false,
    ...props
}: FlagIconProps) {
    const [svgUrl, setSvg] = useState<string>()
    useEffect(() => {
        if (!countryCode) {
            return
        }
        import(`flag-icons/flags/${square ? "1x1" : "4x3"}/${countryCode}.svg`)
            .then((svg) => {
                setSvg(svg.default)
            })
            .catch((e) => {
                error("Failed to load flag icon", e)
            })
    }, [countryCode, square])

    const styles: React.CSSProperties = {}
    if (svgUrl && svgUrl !== "") {
        styles.backgroundImage = `url(${svgUrl})`
    }
    if (square) {
        styles.width = "1em"
    }

    return (
        <span
            className="fi"
            title={countryName}
            // props need to be spread before styles so that they can be overridden
            {...props}
            style={{
                ...styles,
                ...props.style,
            }}
        />
    )
}
