import { AlertProvider } from "../../../hooks/useAlert"
import { AbuseReportLink } from "../AbuseReportLink"

type AbuseLinkSectionProps = {
    roomName: string
}

export function AbuseLinkSection(props: AbuseLinkSectionProps) {
    return (
        <AlertProvider>
            <AbuseReportLink roomName={props.roomName} />
        </AlertProvider>
    )
}
