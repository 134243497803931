import { Button, ButtonColor, ButtonSize } from "../../common"
import type { IBillingProduct } from "../OneClickFlow/OneClickFlow"
import "./OneClickFlowProduct.scss"

interface OneClickFlowProductProps {
    isSelected: boolean
    onSelect: (productId: string) => void
    product: IBillingProduct
}

export function OneClickFlowProduct({
    isSelected,
    onSelect,
    product,
}: OneClickFlowProductProps) {
    const { product_id, percent_bonus_tokens = null, cost, tokens } = product
    const productText = `${tokens} tokens for $${cost} ${
        percent_bonus_tokens ? `(${percent_bonus_tokens}% Bonus)` : ""
    }`
    return (
        <div className="one-click-flow__product">
            <div className="one-click-flow__product__status">
                {isSelected ? <span>&#10003;</span> : ""}
            </div>
            <Button
                color={ButtonColor.Clear}
                size={ButtonSize.Medium}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onSelect(product_id)
                }}
                className="product-button"
                text={productText}
            />
        </div>
    )
}
