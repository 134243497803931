import { generateApiPath } from "../../../routes/util"
import { fetchGet } from "../../../utils/myFetch"

// memoize approved tags promise to prevent multiple request for same title
const memoizedFromTagsList = new Map<string, Promise<string[]>>()
const tagsApprovalMap = new Map<string, boolean>()

// Receives a list of tags and returns a Promise which resolves to a list containing only tags which are approved
export const getApprovedTags = (
    tags: string[],
    languageCode: string,
): Promise<string[]> => {
    if (tags.length === 0) {
        return Promise.resolve([])
    }
    const tagsList = tags.join(",")
    if (memoizedFromTagsList.has(tagsList)) {
        // This function has already been called with this list of tags
        return memoizedFromTagsList.get(tagsList) as Promise<string[]>
    }
    const hasUncheckedTags = tags.find((tag) => !tagsApprovalMap.has(tag))
    if (!hasUncheckedTags) {
        // every tag in list has been checked for approval already
        const approvedTags = tags.filter((tag) => tagsApprovalMap.get(tag))
        memoizedFromTagsList.set(tagsList, Promise.resolve(approvedTags))
    } else {
        memoizedFromTagsList.set(
            tagsList,
            fetchGet(
                `${generateApiPath(
                    languageCode,
                    "hashtags",
                    "approved_from_tags_list/",
                )}?tags=${encodeURIComponent(tags.join(","))}`,
            ).then((resp) => {
                const data = resp.jsonData as { tags: string[] }
                const approvedSet = new Set(data.tags)
                tags.map((tag) =>
                    tagsApprovalMap.set(tag, approvedSet.has(tag)),
                )
                return data.tags
            }),
        )
    }
    return memoizedFromTagsList.get(tagsList) as Promise<string[]>
}

export const getApprovedTagsFromTitle = (
    title: string,
    languageCode: string,
): Promise<string[]> => {
    const tags = Array.from(
        title.matchAll(/#([a-zA-Z0-9\-_]{2,50})/g),
        (m) => m[1],
    )
    return getApprovedTags(
        tags.map((e) => e.toLowerCase()),
        languageCode,
    ).then((approvedTags) => {
        // Limit to 5 approved tags per title
        return approvedTags.slice(0, 5)
    })
}
