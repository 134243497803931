/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { AppContextProvider } from "../../hooks/appContext"
import { AlertProvider } from "../../hooks/useAlert"
import { FlaggedI18nProvider } from "../../i18n"
import { store } from "../../store/store"
import { error } from "../debug"
import type { Root } from "react-dom/client"

type ValidComponent =
    | React.MemoExoticComponent<React.ForwardRefExoticComponent<any>>
    | ((props: any) => JSX.Element)

// makeComponentRegistry adds Components to a window regsitry of Components for use outside of React.
// Use this function when defining Components to be used in TypeScript code.
// Note:  This function should only ever be called once per app, with all Components to be exported passed in.
export function makeComponentRegistry(
    components: Record<string, ValidComponent>,
): void {
    const global = window as any
    if (global["ReactComponentRegistry"] !== undefined) {
        error(
            "ReactComponentRegistry error:  Attempted to makeRegistry when registry already exists.",
        )
    }
    global["ReactComponentRegistry"] = {}
    Object.entries(components).forEach(([name, Func]) => {
        global["ReactComponentRegistry"][name] = exportReactComponent(Func)
    })
}

const exportReactComponent = (Func: ValidComponent) => {
    return class {
        private root: Root
        private props: Record<string, any>

        constructor(props: Record<string, any>, target: HTMLElement) {
            this.root = createRoot(target)
            this.props = props
            this.render()
        }

        private render() {
            this.root.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <AppContextProvider context={window as any}>
                            <AlertProvider>
                                <FlaggedI18nProvider>
                                    <Func {...this.props} />
                                </FlaggedI18nProvider>
                            </AlertProvider>
                        </AppContextProvider>
                    </Provider>
                </React.StrictMode>,
            )
        }

        update(props: Record<string, any>) {
            this.props = {
                ...this.props,
                ...props,
            }
            this.render()
        }

        dispose() {
            this.root.unmount()
        }
    }
}
