import React from "react"
import "./SwipeToConfirmButton.scss"

interface ReactSwipeButtonProps {
    onSuccess: () => void
    disabled: boolean
}

const SwipeToConfirmButton = ({
    onSuccess,
    disabled,
}: ReactSwipeButtonProps) => {
    const dragItem = React.useRef<HTMLDivElement>(null)
    const container = React.useRef<HTMLDivElement>(null)
    const textBefore = React.useRef<HTMLParagraphElement>(null)
    const textAfter = React.useRef<HTMLParagraphElement>(null)
    const textEnd = React.useRef<HTMLParagraphElement>(null)
    const currentX = React.useRef(0)
    const initialX = React.useRef(0)
    const dragWidth = React.useRef(0)
    const active = React.useRef(false)

    function getDragImage() {
        const img = new Image()
        img.src = "fake.gif"
        return img
    }

    function dragStart(e: any) {
        if (e.target === dragItem.current && !active.current) {
            active.current = true
        }
        if (e.type === "dragstart") {
            e?.dataTransfer?.setDragImage(getDragImage(), 0, 0)
        }

        if (e.type === "touchstart") {
            initialX.current = e.touches[0].clientX
        } else {
            initialX.current = e.clientX
        }
    }

    function dragEnd() {
        if (currentX.current < dragWidth.current - 5) {
            animateBack()
        } else {
            completed()
        }
        active.current = false
    }

    function drag(e: any) {
        if (active.current) {
            e.cancellable && e.preventDefault()
            if (e.type === "touchmove") {
                currentX.current = e.touches[0].clientX - initialX.current
            } else {
                currentX.current = e.clientX - initialX.current
            }

            if (currentX.current >= dragWidth.current - 5) {
                completed()
                onSuccess()
                active.current = false
            }

            if (currentX.current > 0 && currentX.current < dragWidth.current) {
                setTranslate(currentX.current, dragItem.current)
            }
        }
    }

    function setTranslate(xPos: number, el: HTMLElement | null) {
        if (el) {
            el.style.transform = `translate3d(${xPos}px, ${0}px, 0)`
        }
        if (textEnd.current && textAfter.current && textBefore.current) {
            textEnd.current.style.opacity = "0"
            if (xPos > dragWidth.current / 2) {
                textAfter.current.style.opacity = "1"
                textBefore.current.style.opacity = "0"
            } else {
                textAfter.current.style.opacity = "0"
                textBefore.current.style.opacity = "1"
            }
        }
    }

    function animateBack() {
        if (
            container.current &&
            textEnd.current &&
            textAfter.current &&
            textBefore.current
        ) {
            // turn off/on animations to speed up the fallback
            dragItem.current?.classList?.toggle("animate")
            container.current.classList.toggle("animate")
            textBefore.current.classList.toggle("animate")
            textAfter.current.classList.toggle("animate")
            setTranslate(0, dragItem.current)
            setTimeout(() => {
                if (
                    container.current &&
                    dragItem.current &&
                    textAfter.current &&
                    textBefore.current
                ) {
                    // wait for the animation is done before turning animations back on/off
                    dragItem.current.classList.toggle("animate")
                    container.current.classList.toggle("animate")
                    textBefore.current.classList.toggle("animate")
                    textAfter.current.classList.toggle("animate")
                }
            }, 1)
        }
    }

    function completed() {
        setTranslate(dragWidth.current, dragItem.current)
        if (textEnd.current && textAfter.current && textBefore.current) {
            textEnd.current.style.opacity = "1"
            textAfter.current.style.opacity = "0"
            textBefore.current.style.opacity = "0"
        }
    }

    React.useEffect(() => {
        if (container?.current && dragItem.current) {
            dragWidth.current =
                container?.current?.clientWidth - dragItem.current?.clientWidth
        }
    }, [container.current?.clientWidth, dragItem?.current?.clientWidth])

    return (
        <div
            ref={container}
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            onDrag={drag}
            onTouchStart={dragStart}
            onTouchEnd={dragEnd}
            onTouchMove={drag}
            className={`track_drag animate${disabled ? " disabled" : ""}`}
        >
            <div draggable ref={dragItem} className="oneclick-item" />
            <p ref={textEnd} className="track_text track_text--after animate">
                Confirmed...
            </p>
            <p ref={textAfter} className="track_text track_text--after animate">
                Confirming...
            </p>
            <p
                ref={textBefore}
                className="track_text track_text--before animate"
            >
                {disabled ? "Enter CVV2/CVC code" : "Swipe to Confirm"}
            </p>
        </div>
    )
}

export default SwipeToConfirmButton
