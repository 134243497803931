import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCouple = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="url(#couple_svg__a)"
            d="M11.483 7.89H4.577a.364.364 0 0 1-.384-.342V6.261c0-.188.181-.208.384-.341L8.03 4.15l3.453 1.775c.226.14.384.153.384.341v1.282a.364.364 0 0 1-.384.342"
        />
        <path
            fill="url(#couple_svg__b)"
            d="M8.63 7.611V4.755l2.783 1.217q.094.056.173.093c.14.07.14.078.14.118V7.42a.226.226 0 0 1-.25.194H8.63zm-4.053 0a.226.226 0 0 1-.25-.193V6.18c0-.04.012-.056.147-.12a2 2 0 0 0 .171-.094l2.833-1.242v2.886z"
        />
        <path
            fill="url(#couple_svg__c)"
            d="M8.63 7.611V4.755l2.783 1.217q.093.056.173.093c.14.07.14.078.14.118V7.42a.226.226 0 0 1-.25.194H8.63zm-4.053 0a.226.226 0 0 1-.25-.193V6.18c0-.04.012-.056.147-.12a2 2 0 0 0 .17-.094l2.834-1.242v2.886z"
        />
        <path fill="url(#couple_svg__d)" d="M7.456 5.747h1.151V7.89H7.456z" />
        <path
            fill="url(#couple_svg__e)"
            d="m9.024 5.731-.15.194h-1.69l-.148-.194V3.676h1.988z"
        />
        <path
            fill="url(#couple_svg__f)"
            d="M9.787 1.995a.21.21 0 0 0-.176.103V1.54c0-.286-.168-1.426-1.33-1.426h-.575c-1.003 0-1.287 1.14-1.287 1.426v.522a.2.2 0 0 0-.145-.068.25.25 0 0 0-.221.25l.062.59a.25.25 0 0 0 .221.252.2.2 0 0 0 .143-.063v.597c0 .286.979 1.184 1.232 1.184h.57c.251 0 1.237-.91 1.237-1.196v-.56a.21.21 0 0 0 .176.103.25.25 0 0 0 .22-.251l.094-.655a.25.25 0 0 0-.221-.251"
        />
        <path
            fill="url(#couple_svg__g)"
            d="M9.787 1.995a.21.21 0 0 0-.176.103V1.54c0-.286-.168-1.426-1.33-1.426h-.575c-1.003 0-1.287 1.14-1.287 1.426v.522a.2.2 0 0 0-.145-.068.25.25 0 0 0-.221.25l.062.59a.25.25 0 0 0 .221.252.2.2 0 0 0 .143-.063v.597c0 .286.979 1.185 1.232 1.185h.57c.251 0 1.237-.911 1.237-1.197v-.56a.21.21 0 0 0 .176.103.25.25 0 0 0 .22-.251l.094-.655a.25.25 0 0 0-.221-.251"
        />
        <path
            fill="#4C3D3D"
            d="M9.611 1.54c0-.285-.075-1.425-1.317-1.425h-.62c-1.082 0-1.255 1.14-1.255 1.426 0 0-.105 1.254.173 1.505l.1-1.104c0-.286.252-.753.706-.622.42.1.857.1 1.277 0 .331-.095.59.324.59.61L9.4 3.054c.241-.228.211-1.513.211-1.513"
        />
        <rect
            width={1.84}
            height={0.97}
            x={7.082}
            y={0.167}
            fill="url(#couple_svg__h)"
            opacity={0.5}
            rx={0.485}
            style={{
                mixBlendMode: "screen",
            }}
        />
        <use href="#CoupleIconG" xlinkHref="#CoupleIconG" />
    </svg>
)
const ForwardRef = forwardRef(SvgCouple)
const Memo = memo(ForwardRef)
export default Memo
