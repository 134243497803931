import React, { useCallback, useEffect, useState } from "react"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes } from "../../../interfaces/sizing"
import { generateApiPath } from "../../../routes/util"
import { error } from "../../../utils/debug"
import { fetchGet } from "../../../utils/myFetch"
import { Typography } from "../../common"
import { ContestLeaderboardTable } from "../ContestLeaderboardTable"
import { ContestPayoutInfo } from "../ContestPayoutInfo"
import { ContestStatsTopSection } from "../ContestStatsTopSection"
import { ContestUserStatistics } from "../ContestUserStatistics"
import "./ContestStats.scss"

interface ContestStatsProps {
    /** Whether to show contest stats or not. stats are usually a component of tabs and should be hidden when
     * it's not currently the contest tab */
    show: boolean
    /** Whether to show additional details (such as current statistics and payout info), for broadcast and profile views */
    withAdditionalDetails: boolean
    /** fires event router loadRoomRequest */
    loadRoomRequestFire?: (event: string) => void
}

export interface IContestStat {
    room_slug: string
    viewers: number
    points: number
    room_user: string
    image_url: string
}

export interface BroadcasterContestStat {
    /** Current number of viewers for the broadcaster */
    viewers: number
    /** Current number of points for the broadcaster */
    points: number
    /** Current contest ranking for the broadcaster */
    rank: string
    /** Start date of the contest */
    start: string
    /** End date of the contest */
    end: string
}

interface GetContestLeaderBoardResponse extends BroadcasterContestStat {
    /** current top 10 for the contest */
    top: IContestStat[]
}

export function ContestStats({
    show,
    withAdditionalDetails,
    loadRoomRequestFire,
}: ContestStatsProps) {
    const [isLoading, setIsLoading] = useState(true)
    const [broadcasterStatsData, setBroadcasterStatsData] = useState<
        BroadcasterContestStat | undefined
    >(undefined)
    const [contestStatsData, setContestStatsData] = useState<IContestStat[]>([])
    const languageCode = useAppContext().context.language_code
    const contestLeaderboardApiPath = `${generateApiPath(
        languageCode,
        "contest",
        "leaderboard",
    )}`

    const resetState = useCallback(() => {
        setIsLoading(true)
        setBroadcasterStatsData(undefined)
        setContestStatsData([])
    }, [])

    const loadContent = useCallback(async () => {
        setIsLoading(true)
        try {
            const response = await fetchGet(
                `${contestLeaderboardApiPath}?_=${new Date().getTime()}`,
            )
            const parsedResponse =
                response.jsonData as GetContestLeaderBoardResponse
            const { top, viewers, points, rank, start, end } = parsedResponse
            const dateOptions: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
            }
            const startDate = new Date(start).toLocaleString(
                languageCode,
                dateOptions,
            )
            const endDate = new Date(end).toLocaleString(
                languageCode,
                dateOptions,
            )
            setContestStatsData(top)
            setBroadcasterStatsData({
                viewers,
                points,
                rank,
                start: startDate,
                end: endDate,
            })
        } catch (err) {
            error(err)
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }, [contestLeaderboardApiPath, languageCode])

    useEffect(() => {
        if (show) {
            loadContent()
            return
        }
        resetState()
    }, [show, loadContent, resetState])

    return (
        <>
            {show && (
                <div className="ContestStats">
                    {isLoading ? (
                        <Typography component="div" size={Sizes.xs}>
                            Loading..
                        </Typography>
                    ) : (
                        <>
                            <ContestStatsTopSection
                                withRefreshButtonAndDetails={
                                    withAdditionalDetails
                                }
                                refreshButtonOnClick={loadContent}
                            />
                            {withAdditionalDetails &&
                                broadcasterStatsData !== undefined && (
                                    <ContestUserStatistics
                                        {...broadcasterStatsData}
                                    />
                                )}
                            <ContestLeaderboardTable
                                contestStats={contestStatsData}
                                openLinksInNewTab={withAdditionalDetails}
                                loadRoomRequestFire={loadRoomRequestFire}
                            />
                            {withAdditionalDetails && <ContestPayoutInfo />}
                        </>
                    )}
                </div>
            )}
        </>
    )
}
