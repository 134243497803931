import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgButtonTriangle = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="9"
        fill="none"
        viewBox="0 0 7 9"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#fff"
            d="M5.641 3.624a.5.5 0 0 1 0 .752L1.758 7.774a.5.5 0 0 1-.83-.376V.602a.5.5 0 0 1 .83-.376z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgButtonTriangle)
const Memo = memo(ForwardRef)
export default Memo
