// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../static/close-lighter-gray.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomFlagNotification{background-color:#306a91;border-radius:4px;box-shadow:0px 2px 6px 0px rgba(0,0,0,.2509803922);box-sizing:border-box;color:#fff;display:flex;flex-direction:column;font:1.2rem/1.8rem "UbuntuRegular",Arial,Helvetica,sans-serif;font-weight:400;height:fit-content;width:31.6rem;padding:.8rem .8rem .8rem 1.4rem;position:absolute;z-index:1}.RoomFlagNotification .RoomFlagNotification__subject-container{align-items:flex-start;display:inline-flex;height:fit-content;justify-content:space-between;padding-bottom:.8rem}.RoomFlagNotification .RoomFlagNotification__subject{display:inline;font-family:"UbuntuRegular",Arial,Helvetica,sans-serif;font-size:1.6rem;line-height:2rem}.RoomFlagNotification .RoomFlagNotification__message{padding-bottom:.2rem}.RoomFlagNotification .RoomFlagNotification__close-button{background:rgba(0,0,0,0) url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;background-size:contain;height:1.2rem;cursor:pointer;width:1.2rem}.RoomFlagNotification::before{content:"";position:absolute;top:100%;left:calc(50% - 1.3rem);width:0;border-top:1.2rem solid #306a91;border-left:1.3rem solid rgba(0,0,0,0);border-right:1.3rem solid rgba(0,0,0,0)}.RoomFlagNotification .RoomFlagNotification__flag-display-container .fi{margin-right:3px}.RoomFlagNotification .RoomFlagNotification__button-container{align-items:center;display:flex;justify-content:end;-webkit-justify-content:flex-end;margin-top:1.8rem;width:100%}.RoomFlagNotification .RoomFlagNotification__button{background-color:#f47321;border-radius:4px;color:#fff;cursor:pointer;display:flex;align-items:center;justify-content:center;font-family:"UbuntuMedium",Arial,Helvetica,sans-serif;padding:.5rem 0;text-overflow:ellipsis;width:9.7rem}.RoomFlagNotification .fi{display:inline-flex;margin-left:.4rem}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
