// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../static/triangle.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MenuLabel-container{display:flex;justify-content:space-between;align-items:center;line-height:20px;height:60px;font-size:16px;color:#222;border-bottom:1px solid #efefef;box-sizing:border-box;padding:12px 18px}.MenuLabel-container.back{justify-content:inherit;background-color:#fff;height:45px}.MenuLabel{width:100%}.MenuLabel.chevron.back::before{content:"";width:16px;height:8px;transform:rotate(-90deg);background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;float:right;padding-top:12px}.MenuLabel.chevron.back::before{content:"";transform:rotate(90deg);float:left;margin-right:16px}.MenuLabel.chevron:not(.back)::before{content:"";width:16px;height:8px;transform:rotate(-90deg);background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;float:right;padding-top:12px}.MenuLabel .MenuLabel-span{overflow:hidden;text-overflow:ellipsis}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
