import { newrelic } from "@multimediallc/web-utils"

let currentRoom: string | undefined

export function newRelicEnabled(): boolean {
    return newrelic.newRelicEnabled()
}

export function setCurrentRoom(room: string): void {
    currentRoom = room
}

function addNamespaceAndDefault(
    attributes: Record<string, string | number> = {},
): Record<string, string | number> {
    const namespacedAttributes: Record<string, string | number> = {}
    for (const key in attributes) {
        namespacedAttributes[`attributes.${key}`] = attributes[key]
    }
    if (namespacedAttributes["attributes.room_user"] === undefined) {
        namespacedAttributes["attributes.room_user"] = currentRoom ?? ""
    }
    if (
        namespacedAttributes["attributes.room_user"] !== undefined &&
        typeof namespacedAttributes["attributes.room_user"] === "string"
    ) {
        const regex = /[^a-zA-Z0-9]+/
        namespacedAttributes["attributes.room_user"] = namespacedAttributes[
            "attributes.room_user"
        ].replace(regex, "")
    }
    return namespacedAttributes
}

export function addPageAction(name: string, attributes = {}): void {
    const namespacedAttributes = addNamespaceAndDefault(attributes)
    newrelic.addPageAction(name, namespacedAttributes)
}

// This affects all PageActions, past and present, do not use for data that needs to be updated/changed
export function setCustomAttribute(name: string, value: string): void {
    newrelic.setCustomAttribute(name, value)
}
