import { type MouseEvent, useCallback } from "react"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { useAlert } from "../../../hooks/useAlert"
import { AlertType, Typography } from "../../common"
import "./OneClickFlowMain.scss"
import { OneClickPayMethod, PAGES } from "../OneClickFlow/OneClickFlow"
import type {
    GetOneClickEligibleProductsResponse,
    IBillingProduct,
} from "../OneClickFlow/OneClickFlow"

const i18n = {
    purchaseTokens: gettext("Purchase Tokens"),
    tokenAmount: gettext("Token Amount"),
    payWith: gettext("Pay with"),
    purchase: gettext("Purchase"),
    edit: gettext("Edit"),
    total: gettext("Total"),
    leavePrivateShowWarningText: gettext(
        "To avoid ending the private show, a new tab will open. Click OK to continue.",
    ),
    tokenBalance: (tokens: number) =>
        interpolate(
            gettext(
                "You have&nbsp;<span class='type--bold'>%(tokens)s</span>&nbsp;tokens",
            ),
            { tokens: tokens },
            true,
        ),
}

interface OneClickFlowMainProps {
    setPage: (page: (typeof PAGES)[keyof typeof PAGES]) => void
    product: IBillingProduct
    payMethod: GetOneClickEligibleProductsResponse["paymethod"]
    roomType?: string
    desktop?: boolean
    handleCvvChange?: (value: string) => void
    tokenBalance?: number
    openPurchasePage: () => void
}

export function OneClickFlowMain({
    setPage,
    product,
    payMethod,
    roomType,
    desktop = false,
    handleCvvChange,
    tokenBalance = 0,
    openPurchasePage,
}: OneClickFlowMainProps) {
    const addAlert = useAlert()
    const method = payMethod?.method
    const { card_type, last4 } = payMethod?.payload

    const changeToken = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            e.stopPropagation()
            setPage(PAGES.PRODUCTS)
        },
        [setPage],
    )

    return (
        <>
            <Typography
                className="one-click-flow__scene-title"
                size="md"
                weight="bold"
            >
                {i18n.purchaseTokens}
            </Typography>
            <div className="one-click-flow__pay-info">
                {desktop && (
                    <div
                        className="one-click-flow__pay-info__row token-balance"
                        dangerouslySetInnerHTML={{
                            __html: i18n.tokenBalance(tokenBalance),
                        }}
                    />
                )}
                <div className="one-click-flow__pay-info__row">
                    <div>{desktop ? i18n.purchase : i18n.tokenAmount}:</div>
                    <button onClick={changeToken}>
                        <span className="one-click-flow__pay-info__text">
                            <span className="type--bold">{product.tokens}</span>{" "}
                            tokens
                            {product?.percent_bonus_tokens
                                ? ` (${product?.percent_bonus_tokens}% bonus) `
                                : " "}
                        </span>
                        <span className="one-click-flow__pay-info__link">
                            {i18n.edit}
                        </span>
                    </button>
                </div>
                <div className="one-click-flow__pay-info__row">
                    <div>Pay With:</div>
                    <a
                        onClick={() => {
                            if (roomType === "private" && !desktop) {
                                addAlert({
                                    type: AlertType.confirm,
                                    title: "",
                                    contents: i18n.leavePrivateShowWarningText,
                                    yesCallback: openPurchasePage,
                                })
                                return
                            }
                            openPurchasePage()
                        }}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span className="one-click-flow__pay-info__text">
                            {method === OneClickPayMethod.SAVED_CARD
                                ? `${card_type} *${last4}`
                                : "*Epoch Information on File*"}{" "}
                        </span>
                        <span className="one-click-flow__pay-info__link">
                            {i18n.edit}
                        </span>
                    </a>
                    {handleCvvChange && (
                        <input
                            onChange={(e) => handleCvvChange(e.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                }
                            }}
                            placeholder="CVV2"
                            className="cvv-input"
                            type="tel"
                            inputMode="numeric"
                            maxLength={4}
                        />
                    )}
                </div>
                <div className="one-click-flow__pay-info__row">
                    <div>{i18n.total}:</div>
                    <div className="one-click-flow__pay-info__text">
                        {`$${product.cost}`}
                    </div>
                </div>
            </div>
        </>
    )
}
