import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgHomepageFiltersUnappliedIcon = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 10"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#306A91"
            fillRule="evenodd"
            d="M0 .75A.75.75 0 0 1 .75 0h10.5a.75.75 0 0 1 0 1.5H.75A.75.75 0 0 1 0 .75m1 4A.75.75 0 0 1 1.75 4h6.5a.75.75 0 0 1 0 1.5h-6.5A.75.75 0 0 1 1 4.75M2.75 8a.75.75 0 0 0 0 1.5h2.5a.75.75 0 0 0 0-1.5z"
            clipRule="evenodd"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgHomepageFiltersUnappliedIcon)
const Memo = memo(ForwardRef)
export default Memo
