import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgBadgeIcon = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 10 10"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <g fill="#F47321">
            <circle cx={5} cy={5} r={5} fillOpacity={0.15} />
            <circle cx={5} cy={5} r={3.75} fillOpacity={0.25} />
            <circle cx={5} cy={5} r={1.875} fillOpacity={0.7} />
        </g>
    </svg>
)
const ForwardRef = forwardRef(SvgBadgeIcon)
const Memo = memo(ForwardRef)
export default Memo
