import { PaginationDirectionButton } from "../PaginationDirectionButton"
import { PaginationDropdown } from "../PaginationDropdown"
import "./MobilePagination.scss"

interface MobilePaginationProps {
    currPage: number
    numPages: number
    getHref: (page: number) => string
    onPageChange: (page: number) => void
}

export function MobilePagination({
    currPage,
    numPages,
    getHref,
    onPageChange,
}: MobilePaginationProps) {
    return (
        <div
            className="MobilePagination"
            data-testid="room-list-pagination-component"
            style={numPages <= 1 ? { display: "none" } : undefined}
        >
            <ul data-paction="PageNumber">
                <PaginationDirectionButton
                    direction="prev"
                    disabled={currPage === 1}
                    getHref={getHref}
                    onPageChange={onPageChange}
                    targetPage={currPage - 1}
                />
                <PaginationDropdown
                    currPage={currPage}
                    numPages={numPages}
                    onPageChange={onPageChange}
                />
                <PaginationDirectionButton
                    direction="next"
                    disabled={currPage === numPages}
                    onPageChange={onPageChange}
                    getHref={getHref}
                    targetPage={currPage + 1}
                />
            </ul>
        </div>
    )
}
