import { gettext } from "@multimediallc/web-utils"
import { Button, ButtonColor, Typography } from "../../common"
import "./OneClickFlowProductList.scss"
import { type IBillingProduct, PAGES } from "../OneClickFlow/OneClickFlow"
import { OneClickFlowProduct } from "../OneClickFlowProduct"

interface OneClickFlowProductsProps {
    setPage: (page: (typeof PAGES)[keyof typeof PAGES]) => void
    setSelected: (productId: string) => void
    /** `product_id` of selected product from `products` */
    selectedProduct: string
    products: IBillingProduct[]
}

export function OneClickFlowProducts({
    setPage,
    setSelected,
    selectedProduct,
    products,
}: OneClickFlowProductsProps) {
    const i18n = {
        tokenAmount: gettext("Token Amount"),
    }

    const handleSelectProduct = (id: string) => {
        setSelected(id)
        setPage(PAGES.MAIN)
    }
    return (
        <div className="one-click-flow__products">
            <div className="one-click-flow__products__title">
                <Button
                    color={ButtonColor.Clear}
                    text="&#8249;"
                    onClick={() => setPage(PAGES.MAIN)}
                    className="one-click-flow__products__back-button"
                />
                <Typography
                    className="one-click-flow__scene-title"
                    size="md"
                    weight="bold"
                >
                    {i18n.tokenAmount}
                </Typography>
            </div>
            <div className="one-click-flow__products__container">
                {products.map((product) => (
                    <OneClickFlowProduct
                        key={product.product_id}
                        isSelected={product.product_id === selectedProduct}
                        onSelect={handleSelectProduct}
                        product={product}
                    />
                ))}
            </div>
        </div>
    )
}
