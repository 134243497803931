import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgCloseButton = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="#4C4C4C"
            d="M7.15 6.092a.125.125 0 0 1 0-.177l4.631-4.63A.75.75 0 1 0 10.72.223L6.09 4.853a.125.125 0 0 1-.178 0L1.281.223A.75.75 0 0 0 .218 1.285l4.63 4.631a.125.125 0 0 1 0 .177L.22 10.724a.749.749 0 0 0 .531 1.28.75.75 0 0 0 .53-.22l4.631-4.631a.125.125 0 0 1 .177 0l4.631 4.631a.75.75 0 0 0 1.061-1.06z"
        />
    </svg>
)
const ForwardRef = forwardRef(SvgCloseButton)
const Memo = memo(ForwardRef)
export default Memo
