import { useEffect } from "react"
import type { ReactNode, SyntheticEvent } from "react"
import { gettext } from "@multimediallc/web-utils"
import { createPortal } from "react-dom"
import { KeyboardEventHandlerChain } from "../../../../utils/keyboardEventHandler"
import { Button, ButtonColor } from "../../index"
import "./Alert.scss"

const i18n = {
    confirmOK: gettext("OK"),
    cancelText: gettext("Cancel"),
}

export enum AlertType {
    alert = "alert",
    confirm = "confirm",
}

export interface IAlert {
    /**
     * The type of alert to display
     */
    type: AlertType
    /**
     * click handler for the affirmative action
     */
    yesCallback?: () => void
    /**
     * click handler for the negative action
     */
    noCallback?: () => void
    /**
     * The title to display for the alert
     */
    title: string
    /**
     * the content of the alert
     */
    contents: ReactNode
}

interface AlertProps extends IAlert {
    closeModal: () => void
}

export function Alert(props: AlertProps) {
    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler)

        return () => {
            document.removeEventListener("keydown", keyDownHandler)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlers = new KeyboardEventHandlerChain()
    handlers.addHandler({
        keyCode: 13, // Enter key
        requiresCtrlOrMeta: false,
        handle: () => {
            props.yesCallback?.()
            props.closeModal()
        },
    })
    handlers.addHandler({
        keyCode: 27, // Escape key
        requiresCtrlOrMeta: false,
        handle: () => {
            if (props.noCallback !== undefined) {
                props.noCallback()
            } else {
                props.yesCallback?.()
            }
            props.closeModal()
        },
    })

    const keyDownHandler = (event: KeyboardEvent) => {
        event.stopPropagation()
        event.preventDefault()
        handlers.execute(event)
    }

    const yesClickHandler = (event: SyntheticEvent) => {
        event.stopPropagation()
        if (props.yesCallback !== undefined) {
            props.yesCallback()
        }
        props.closeModal()
    }

    const noClickHandler = (event: SyntheticEvent) => {
        event.stopPropagation()
        if (props.noCallback !== undefined) {
            props.noCallback()
        }
        props.closeModal()
    }

    const preventPropagationClick = (event: SyntheticEvent) => {
        event.stopPropagation()
    }

    return createPortal(
        <div
            className="modalAlert Alert"
            onClick={preventPropagationClick}
            data-testid="Alert-modal"
        >
            <div className="dialog">
                <div
                    className="title"
                    style={{ display: props.title === "" ? "none" : "block" }}
                >
                    {props.title}
                </div>
                <div className="text" data-testid="Alert-text">
                    {props.contents}
                </div>
                <div className="dialogForm">
                    <Button
                        className="AcceptButton"
                        onClick={yesClickHandler}
                        text={i18n.confirmOK}
                        color={ButtonColor.Blue}
                    />
                    {props.type === AlertType.confirm && (
                        <Button
                            className="DeclineButton"
                            onClick={noClickHandler}
                            text={i18n.cancelText}
                            color={ButtonColor.Clear}
                        />
                    )}
                </div>
            </div>
        </div>,
        document.body,
    )
}
