import { type ComponentPropsWithoutRef } from "react"
import "./OptInFlagDisplayHelpText.scss"
import { gettext, interpolate } from "@multimediallc/web-utils"
import { renderToStaticMarkup } from "react-dom/server"
import "flag-icons/css/flag-icons.min.css"

interface OptInFlagDisplayHelpTextProps
    extends ComponentPropsWithoutRef<"span"> {
    countryCode: string
    countryName: string
}

const i18n = {
    showMyCountryFlagOnThumbnail: (flagIcon: string): string => {
        return interpolate(
            gettext(
                "Show my country flag%(flagIcon)s on the home page thumbnail",
            ),
            { flagIcon: flagIcon },
            true,
        )
    },
}

export function OptInFlagDisplayHelpText({
    countryCode,
    countryName,
    ...props
}: OptInFlagDisplayHelpTextProps) {
    const createFlagIcon = () => {
        return (
            <span className="thumbnail_flag">
                <span
                    data-testid="opt-in-flag-diplay-help-text-flag-preview"
                    className={`fi fi-${countryCode}`}
                    title={`${gettext(countryName)}`}
                />
            </span>
        )
    }

    const createFlagIconMarkUp = () => {
        const flagIcon =
            countryCode !== ""
                ? ` ${renderToStaticMarkup(createFlagIcon())}`
                : ""
        return {
            __html: i18n.showMyCountryFlagOnThumbnail(flagIcon),
        }
    }

    return (
        <span
            className="OptInFlagDisplayHelpText"
            {...props}
            dangerouslySetInnerHTML={createFlagIconMarkUp()}
        />
    )
}
