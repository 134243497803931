import { useEffect, useRef, useState } from "react"
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { getCurrentGender } from "@multimediallc/gender-utils"
import { gettext, SPOKEN_LANGUAGES_MAP } from "@multimediallc/web-utils"
import { generateApiPath } from "../../../routes/util"
import { error } from "../../../utils/debug"
import { fetchGet } from "../../../utils/myFetch"
import { LanguageFilterSearchInput } from "../LanguageFilterSearchInput"
import { LanguageFilterSectionOptions } from "../LanguageFilterSectionOptions"

const i18n = {
    languageSectionHeader: gettext("LANGUAGES"),
}

export interface ILanguage {
    code: string
    display_name: string
}

export interface ISearchLanguagesResponse {
    languages: string[]
}

export function searchSpokenLanguages(params: {
    search?: string
    sort?: "room_count"
}): Promise<ILanguage[]> {
    const queryParams = new URLSearchParams({
        ...params,
        genders: getCurrentGender(),
    })
    const apiPath = `${generateApiPath("en", "roomlist", "spoken-languages-search/")}?${queryParams.toString()}`
    return fetchGet(apiPath).then((resp) => {
        const languageCodes = (resp.jsonData as ISearchLanguagesResponse)
            .languages
        return languageCodes.reverse().map((langCode) => {
            return {
                code: langCode,
                display_name: SPOKEN_LANGUAGES_MAP.get(langCode) ?? "",
            }
        })
    })
}

interface LanguageFilterSectionProps {
    onFilterOptionClick: (selectedLanguage: string) => void
    getToggledHref: (language: string) => string
}

export function LanguageFilterSection({
    onFilterOptionClick,
    getToggledHref,
}: LanguageFilterSectionProps) {
    const [selectedLanguage, setSelectedLanguage] = useState<string>("")
    const [languageSectionOptions, setLanguageSectionOptions] = useState<
        ILanguage[]
    >([])
    const ref = useRef<HTMLDivElement>(null)

    const updateLanguageOptions = () => {
        searchSpokenLanguages({ sort: "room_count" })
            .then((languages) => {
                setLanguageSectionOptions(languages)
            })
            .catch((e) => error(e))
    }

    useEffect(updateLanguageOptions, [])

    useEffect(() => {
        setSelectedLanguage(UrlState.current.state.spokenLanguages?.[0] ?? "")
    }, [])

    useEffect(() => {
        if (ref.current === null) {
            return
        }
        UrlState.current.listen(
            ["spokenLanguages"],
            (state) => {
                setSelectedLanguage(state.spokenLanguages?.[0] ?? "")
            },
            ref.current,
        )
        UrlState.current.listen(["genders"], updateLanguageOptions, ref.current)
    }, [ref])

    const handleLanguageClick = (language: string) => {
        onFilterOptionClick(language)
        const newLanguageSelection =
            selectedLanguage !== language ? language : ""
        setSelectedLanguage(newLanguageSelection)
    }

    const handleLanguageClickNoToggle = (language: string) => {
        if (language !== selectedLanguage) {
            handleLanguageClick(language)
        }
    }

    return (
        <div ref={ref} className="LanguageFilterSection filterSection">
            <div
                className="filterSectionHeader"
                data-testid="language-filter-header"
            >
                {i18n.languageSectionHeader}
            </div>
            <LanguageFilterSearchInput
                onLanguageClick={handleLanguageClickNoToggle}
            />
            <LanguageFilterSectionOptions
                options={languageSectionOptions}
                selectedLanguage={selectedLanguage}
                onLanguageClick={handleLanguageClick}
                getToggledHref={getToggledHref}
            />
        </div>
    )
}
