import "./PaginationDirectionButton.scss"

interface PaginationDirectionButtonProps {
    direction: "prev" | "next"
    disabled: boolean
    getHref: (page: number) => string
    onPageChange: (page: number) => void
    targetPage: number
}

export function PaginationDirectionButton({
    direction,
    disabled,
    getHref,
    onPageChange,
    targetPage,
}: PaginationDirectionButtonProps) {
    return (
        <li className="PaginationDirectionButton">
            <a
                className={`${direction}-button${disabled ? " no-link" : ""}`}
                href={disabled ? "#" : getHref(targetPage)}
                data-testid={`${direction}-button`}
                onClick={(e) => {
                    if (disabled) {
                        return
                    }
                    if (e.ctrlKey || e.metaKey || e.shiftKey) {
                        return
                    }
                    e.preventDefault()
                    onPageChange(targetPage)
                }}
            >
                {direction}
            </a>
        </li>
    )
}
