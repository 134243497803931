import { gettext, testIdCase } from "@multimediallc/web-utils"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import type { BroadcasterContestStat } from "../ContestStats/ContestStats"
import "./ContestUserStatistics.scss"

const i18n = {
    currentContestStatistics: gettext("Current Contest Statistics for"),
    uniqueRegisteredViewers: gettext("Unique Registered Viewers"),
    yourPoints: gettext("Your Points"),
    yourRank: gettext("Your Rank"),
}

export function ContestUserStatistics({
    viewers,
    points,
    rank,
    start,
    end,
}: BroadcasterContestStat) {
    const statsData = [
        {
            value: viewers,
            label: i18n.uniqueRegisteredViewers,
        },
        {
            value: points,
            label: i18n.yourPoints,
        },
        {
            value: rank,
            label: i18n.yourRank,
        },
    ]
    return (
        <div className="ContestUserStatistics">
            <Typography
                weight={Weights.medium}
                component="h2"
                className="label heading"
                data-testid="current-contest-statistics-label"
            >
                {i18n.currentContestStatistics}:
                <Typography component="span" className="contentText">
                    {` ${start} to ${end}`}
                </Typography>
            </Typography>
            <dl className="contentText statsWrapper">
                {statsData.map((stat, index) => {
                    const { value, label } = stat
                    const labelTestId = testIdCase(label)
                    return (
                        <div key={index}>
                            <Typography
                                weight={Weights.bold}
                                size={Sizes.sm}
                                component="dt"
                                className="contentText statsLabel"
                                data-testid={`contest-${labelTestId}-label`}
                            >
                                {label}:
                            </Typography>
                            <Typography
                                size={Sizes.sm}
                                component="dd"
                                data-testid={`contest-${labelTestId}-value`}
                            >
                                {value}
                            </Typography>
                        </div>
                    )
                })}
            </dl>
        </div>
    )
}
