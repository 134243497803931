import "./PrivateShowBadge.scss"
import { gettext } from "@multimediallc/web-utils"
import { BadgeIcon } from "../../common/atoms/Icons/Chat"

const i18n = {
    privateShowText: gettext("Private Show"),
}

export function PrivateShowBadge() {
    return (
        <div className="private-show-badge" data-testid="private-show-badge">
            <BadgeIcon className="badge-image" />
            <span className="badge-text">{i18n.privateShowText}</span>
        </div>
    )
}
