import type { PointerEvent } from "react"
import { useEffect, useRef } from "react"
import { gettext } from "@multimediallc/web-utils"
import { isIOS } from "react-device-detect"
import { useAlert } from "../../../../hooks/useAlert"
import { Sizes } from "../../../../interfaces/sizing"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import {
    setNoteCache,
    trimNoteCache,
    useGetNoteForUserQuery,
    useSaveNoteForUserMutation,
} from "../../../../store/userList/notesSlice"
import { error } from "../../../../utils/debug"
import { addPageAction } from "../../../../utils/newrelic"
import {
    AlertType,
    Button,
    ButtonSize,
    Link,
    Textarea,
    Typography,
} from "../../../common"
import { NotesLight } from "../../../common/atoms/Icons/Menu"
import "./UserNote.scss"

interface UserNoteProps {
    username: string
    handleInputFocus?: (event: PointerEvent) => void
    badgeManager: {
        addUser: (username: string) => void
        removeUser: (username: string) => void
    }
    isNotLoggedIn: (msg: string) => boolean
    removeMenu: () => void
}

export function UserNote({
    username,
    handleInputFocus,
    badgeManager,
    isNotLoggedIn,
    removeMenu,
}: UserNoteProps) {
    const currentUser = useAppSelector(
        (state) => state.user.loggedInUser?.username,
    )
    const {
        data: note,
        isLoading,
        isError,
    } = useGetNoteForUserQuery(
        { username },
        { skip: currentUser === undefined },
    )
    useEffect(() => {
        if ((note ?? "").trim() === "") {
            badgeManager.removeUser(username)
        } else {
            badgeManager.addUser(username)
        }
    }, [badgeManager, note, username])

    const addAlert = useAlert()

    const [updateNote] = useSaveNoteForUserMutation()

    const dispatch = useAppDispatch()
    const cachedNote = useAppSelector(
        (state) => state.notes.notesCache[username] ?? "",
    )
    const noteEdited = cachedNote.trim() !== (note ?? "").trim()

    const userNoteText = useRef<HTMLTextAreaElement>(null)
    const submitButton = useRef<HTMLButtonElement>(null)
    const cancelLink = useRef<HTMLAnchorElement>(null)

    useEffect(() => {
        dispatch(trimNoteCache({ username }))
    }, [dispatch, username])

    const save = () => {
        if (
            isNotLoggedIn(
                `You must be logged in to add a Note. Click "OK" to login.`,
            )
        ) {
            return
        }
        addPageAction("NoteSaved", { username: currentUser, to_user: username })
        const trimmedNote = cachedNote.trim()
        updateNote({
            username,
            note: trimmedNote,
        })
            .unwrap()
            .then(() => {
                if (trimmedNote === "") {
                    badgeManager.removeUser(username)
                } else {
                    badgeManager.addUser(username)
                }
                dispatch(trimNoteCache({ username }))
                removeMenu()
            })
            .catch((e) => {
                error("Error saving note", e)
                addAlert({
                    type: AlertType.alert,
                    title: "",
                    contents: "Error saving note",
                })
            })
    }

    const tabHandler = (event: React.KeyboardEvent<any>) => {
        if (event.code === "Tab") {
            if (event.shiftKey) {
                if (document.activeElement === userNoteText.current) {
                    cancelLink.current?.focus()
                } else if (document.activeElement === submitButton.current) {
                    userNoteText.current?.focus()
                } else if (document.activeElement === cancelLink.current) {
                    submitButton.current?.focus()
                }
            } else {
                if (document.activeElement === userNoteText.current) {
                    submitButton.current?.focus()
                } else if (document.activeElement === submitButton.current) {
                    cancelLink.current?.focus()
                } else if (document.activeElement === cancelLink.current) {
                    userNoteText.current?.focus()
                }
            }
            event.preventDefault()
        }
    }

    return (
        <div
            className="userNote"
            onKeyDown={(e) => {
                // Allow for typing any character and tab navigation
                if (e.ctrlKey || e.metaKey || e.key === "Escape") {
                    return
                }
                e.stopPropagation()
            }}
            onClick={(e) => {
                // Stop click events from propegating, so that canvaswindow focus isn't affected
                e.stopPropagation()
            }}
        >
            <div className="userNoteTitle">
                <NotesLight />
                <Typography
                    size={Sizes.xs}
                    className="userNoteTitleText"
                    data-testid="notes-saved-status"
                >
                    {gettext("Note")}
                    {noteEdited && ` ${gettext("(unsaved)")}`}
                </Typography>
            </div>
            <Textarea
                onPointerDown={handleInputFocus}
                className="userNoteText"
                data-testid="user-note-text"
                placeholder={
                    isLoading || isError
                        ? gettext("Loading...")
                        : gettext(
                              "Enter notes about this user (only seen by you)",
                          )
                }
                ref={userNoteText}
                value={cachedNote}
                fontSize={Sizes.xs}
                onChange={(e) => {
                    dispatch(setNoteCache({ username, note: e.target.value }))
                }}
                onKeyDown={(e) => {
                    if (e.shiftKey && e.key === "Enter" && !isIOS) {
                        e.preventDefault()
                        save()
                    }
                    tabHandler(e)
                }}
                maxLength={300}
            />
            {noteEdited && (
                <div className="noteCancelSubmit">
                    <Link
                        href="#"
                        className="cancelLink"
                        data-testid="notes-cancel-button"
                        ref={cancelLink}
                        onKeyDown={tabHandler}
                        onClick={(e) => {
                            e.preventDefault()
                            dispatch(setNoteCache({ username, note }))
                        }}
                    >
                        {gettext("Cancel")}
                    </Link>
                    <Button
                        size={ButtonSize.Medium}
                        className="noteSubmit"
                        data-testid="notes-save-button"
                        ref={submitButton}
                        text={gettext("Save")}
                        onKeyDown={tabHandler}
                        onClick={() => {
                            save()
                        }}
                    />
                </div>
            )}
            <div className="userNoteBottomSpace" />
        </div>
    )
}
