import React, { useEffect, useState } from "react"
import { useAppContext } from "../../../hooks/appContext"
import { getApprovedTagsFromTitle } from "./api"
import { processRoomSubject } from "./subjectParser"
import "./RoomSubjectSpan.scss"

type RoomSubjectSpanProps = {
    text: string
    onHashtagClick?: (ev: React.MouseEvent) => void
}

export const RoomSubjectSpan = React.forwardRef(
    (props: RoomSubjectSpanProps, ref: React.ForwardedRef<HTMLSpanElement>) => {
        const [processedText, setProcessedText] = useState<
            string | JSX.Element
        >(props.text)

        const languageCode = useAppContext().context.language_code

        useEffect(() => {
            let cancelled = false
            setProcessedText(props.text)

            if (!props.text.length || !props.text.includes("#")) {
                return
            }

            getApprovedTagsFromTitle(props.text, languageCode).then(
                (approvedTags) => {
                    // prevent error if component unmounted during API call
                    if (!cancelled) {
                        setProcessedText(
                            processRoomSubject(
                                props.text,
                                approvedTags,
                                props.onHashtagClick,
                            ),
                        )
                    }
                },
            )

            return () => {
                cancelled = true
            }
        }, [props.text, languageCode])

        return (
            <span ref={ref} className="RoomSubjectSpan">
                {processedText}
            </span>
        )
    },
)
