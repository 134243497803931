import type { ComponentPropsWithoutRef } from "react"
import { isLanguageFilterActive } from "@multimediallc/cb-roomlist-prefetch"
import "./MobileFilterPanel.scss"
import { HomepageFiltersIcon } from "../../../filters/HomepageFiltersIcon"

export function MobileFilterPanel({
    ...props
}: ComponentPropsWithoutRef<"div">) {
    return (
        <div className="MobileFilterPanel" {...props}>
            <div className="MobileFilterPanel__HomepageFiltersIconContainer">
                <HomepageFiltersIcon hasSelectedFilters={false} />
            </div>
            <nav className="MobileFilterPanel__Nav">
                <button className="MobileFilterPanel__Button">Tags</button>
                <div className="MobileFilterPanel__Spacer" />
                <button className="MobileFilterPanel__Button">Regions</button>
                <div className="MobileFilterPanel__Spacer" />
                <button className="MobileFilterPanel__Button">Room Size</button>
                <div className="MobileFilterPanel__Spacer" />
                <button className="MobileFilterPanel__Button">
                    Private Show (tokens/min)
                </button>
                <div className="MobileFilterPanel__Spacer" />
                <button className="MobileFilterPanel__Button">Ages</button>
                <div className="MobileFilterPanel__Spacer" />
                {isLanguageFilterActive() && (
                    <button className="MobileFilterPanel__Button">
                        Languages
                    </button>
                )}
                {isLanguageFilterActive() && (
                    <div className="MobileFilterPanel__Spacer" />
                )}
            </nav>
        </div>
    )
}
