import { generateApiPath, normalizeResource } from "../../../routes/util"
import { fetchPost } from "../../../utils/myFetch"

interface MembershipApiResponse {
    id: string
    canceled: boolean
}

export const cancelMembership = async (
    languageCode: string,
    membershipId: string,
): Promise<MembershipApiResponse> => {
    const data = { id: membershipId }
    const response = await fetchPost(
        `${generateApiPath(languageCode, "tipping", "memberships")}/`,
        data,
    )
    return response.jsonData as MembershipApiResponse
}

export const submitFeedback = async (
    languageCode: string,
    reasons: string,
    membershipId: string,
): Promise<void> => {
    const data = {
        source: "cancellation",
        responses: reasons,
        prompts: "Why did you decide to cancel your membership?",
        membership_id: membershipId,
    }
    await fetchPost(normalizeResource(languageCode, "/feedback/submit/"), data)
}
