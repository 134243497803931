import type React from "react"
import "./MembershipFeedbackForm.scss"
import { gettext } from "@multimediallc/web-utils"
import { useAppContext } from "../../../hooks/appContext"
import { useAlert } from "../../../hooks/useAlert"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { error } from "../../../utils/debug"
import {
    AlertType,
    Button,
    ButtonColor,
    Flex,
    TextInput,
    Typography,
} from "../../common"
import { ButtonTriangle } from "../../common/atoms/Icons/Others"
import { cancelMembership, submitFeedback } from "./api"

const i18n = {
    sorryToSeeYouGoCapitalized: gettext("Sorry To See You Go"),
    otherCapitalized: gettext("Other"),
    whyDidYouDecideToCancel: gettext(
        "Why did you decide to cancel your membership?",
    ),
    confirmCancellation: gettext("Confirm Cancellation"),
    dontCancelMyMembership: gettext("Don't cancel my membership"),
    thisSurveyIsOptionalText: gettext(
        "This survey is optional. It is used by Chaturbate to improve your experience.",
    ),
    supporterCancelErrorMessage: gettext(
        "There was an error cancelling your supporter membership.",
    ),
}

interface MembershipFeedbackFormProps {
    /** The Membership ID associated with the feedback form*/
    membershipId: string
    /** 2D array where each array consists of the text and the input id*/
    cancelReasonOptions: string[][]
    /** The function triggered when the don't cancel button is clicked */
    onDontCancelClick: () => void
    /** The function triggered when membership cancellation is successful */
    onCancelSuccess: () => void
    /** shows save notice if user provides feedback */
    showSaveNotice: () => void
    handleReasonSelectionChange: (reason: string) => void
    handleOtherReasonTextChange: (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => void
    reasonSelections: string[]
    otherReasonText: string
}

export function MembershipFeedbackForm({
    membershipId,
    cancelReasonOptions,
    onDontCancelClick,
    onCancelSuccess,
    showSaveNotice,
    handleReasonSelectionChange,
    handleOtherReasonTextChange,
    reasonSelections,
    otherReasonText,
}: MembershipFeedbackFormProps) {
    const languageCode = useAppContext().context.language_code
    const addAlert = useAlert()

    /** Error modal for error during cancelling supporter membership */
    const showErrorModal = (text: React.ReactNode) => {
        addAlert({
            type: AlertType.alert,
            contents: text,
            title: "",
        })
    }

    /** Gets formatted user response(s) so that it's a text, separated by a comma */
    const getformattedUserResponse = (): string => {
        const reasons: string[] = [...reasonSelections]
        if (otherReasonText !== "") {
            reasons.push(`Other - ${otherReasonText}`)
        }
        return reasons.join(",")
    }

    /** Submits the cancellation and feedback (if the user prompts it) */
    const onSubmit = async () => {
        try {
            const cancelMembershipResponse = await cancelMembership(
                languageCode,
                membershipId,
            )
            if (cancelMembershipResponse.canceled) {
                const membershipCancelReasons = getformattedUserResponse()
                if (membershipCancelReasons !== "") {
                    await submitFeedback(
                        languageCode,
                        membershipCancelReasons,
                        membershipId,
                    )
                    showSaveNotice()
                }
                onCancelSuccess()
            } else {
                throw new Error()
            }
        } catch (err) {
            error(err)
            showErrorModal(i18n.supporterCancelErrorMessage)
        }
    }

    /** Prevent submission when cursor is on checkbox/text input */
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    return (
        <div className="MembershipFeedbackForm">
            <Typography
                size={Sizes.xs}
                weight={Weights.bold}
                className="headingText"
            >
                {i18n.sorryToSeeYouGoCapitalized}
            </Typography>
            <form>
                <Typography size={Sizes.xs} className="subHeadingText">
                    {i18n.whyDidYouDecideToCancel}
                </Typography>
                {/* NOTE/TODO: This multiple checkbox component can probably be separated into a reusable component, along with the logic that comes with it */}
                {cancelReasonOptions.map((item: string[]) => {
                    const [reason, id] = item
                    return (
                        <Typography
                            size={Sizes.xs}
                            component="label"
                            htmlFor={id}
                            className="reasonLabel"
                            key={id}
                        >
                            <div className="checkboxContainer">
                                <input
                                    type="checkbox"
                                    id={id}
                                    checked={reasonSelections.includes(reason)}
                                    onChange={() =>
                                        handleReasonSelectionChange(reason)
                                    }
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            {reason}
                        </Typography>
                    )
                })}
                <div className="otherContainer">
                    <Typography
                        size={Sizes.xs}
                        htmlFor="other-reason"
                        component="label"
                    >
                        {i18n.otherCapitalized}
                    </Typography>
                    <TextInput
                        fontSize={Sizes.xs}
                        type="text"
                        placeholder="Type your answer here"
                        id="other-reason"
                        onChange={handleOtherReasonTextChange}
                        maxLength={255}
                        onKeyDown={handleKeyDown}
                        defaultValue={otherReasonText}
                    />
                </div>
                <Typography size={Sizes.xs} className="surveyOptionalText">
                    {i18n.thisSurveyIsOptionalText}
                </Typography>
                {/** TODO: refactor this into a CancelConfirmButtonPair.
                 * 1. Refactor the Button component so that it can accept children,
                 * instead of only text
                 * 2. Refactor the CancelConfirmButtonPair props so it can
                 * accept children for both cancel and confirm buttn
                 */}
                <Flex className="buttonContainer" align="start">
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            onSubmit().catch((err) =>
                                error(
                                    "Error submitting Membership Feedback Form",
                                    { error: err },
                                ),
                            )
                        }}
                        className="confirmButton ButtonColor-orange"
                    >
                        {i18n.confirmCancellation}
                        <ButtonTriangle />
                    </button>
                    <Button
                        color={ButtonColor.Text}
                        onClick={onDontCancelClick}
                        text={i18n.dontCancelMyMembership}
                        className="hrefColor link dontCancelButton"
                    />
                </Flex>
            </form>
        </div>
    )
}
