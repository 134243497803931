import { useState } from "react"
import { gettext } from "@multimediallc/web-utils"
import { AbuseReportModal } from "../AbuseReportModal"
import "./AbuseReportLink.scss"

const i18n = {
    reportAbuse: gettext("Report Room"),
}

interface AbuseReportLinkProps {
    /**
     * roomName is the username for the room we're reporting.
     */
    roomName: string
}

/**
 * This component is responsible for both rendering the "Report Room" link, as well as managing the
 * state of the AbuseReportModal component.
 *
 * TODO: Because the link doesn't actually _navigate_ it should either be a button _or_ we should refactor so
 * that clicking the link navigates to a url that represents the modal open state. Something like `/room/report`?
 *
 * TODO: Refactor to utilize useAlert to manage the modal instead of managing it ad-hoc
 */
export function AbuseReportLink({ roomName }: AbuseReportLinkProps) {
    const [showModal, setShowModal] = useState(false)

    return (
        <div className="reportAbuseLink">
            <a
                className="reportRoom"
                data-testid="report-room-button"
                data-paction="ReportAbuse"
                onClick={() => {
                    setShowModal(true)
                }}
            >
                {i18n.reportAbuse}
            </a>
            {showModal && (
                <AbuseReportModal
                    closeModal={() => {
                        setShowModal(false)
                    }}
                    roomName={roomName}
                    testId="abuse-report-modal"
                />
            )}
        </div>
    )
}
