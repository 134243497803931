import type { ChangeEvent } from "react"
import "./PaginationDropdown.scss"

interface PaginationDropdownProps {
    currPage: number
    numPages: number
    onPageChange: (page: number) => void
}

export function PaginationDropdown({
    currPage,
    numPages,
    onPageChange,
}: PaginationDropdownProps) {
    const pageArray = Array.from(Array(numPages), (_, i) => i + 1)
    return (
        <li className="PaginationDropdown">
            <select
                className="mselect"
                data-testid="page-dropdown-select"
                value={currPage}
                onChange={(e: ChangeEvent) => {
                    onPageChange(
                        parseInt((e.target as HTMLSelectElement).value),
                    )
                }}
            >
                {pageArray.map((pageNum: number) => (
                    <option value={pageNum} key={pageNum}>
                        {pageNum === currPage
                            ? `Viewing Page #${pageNum}`
                            : `Go to Page #${pageNum}`}
                    </option>
                ))}
            </select>
        </li>
    )
}
