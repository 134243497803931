import { gettext } from "@multimediallc/web-utils"
import { Weights } from "../../../interfaces/sizing"
import { Typography } from "../../common"
import { ContestLeaderboardRow } from "../ContestLeaderboardRow"
import type { IContestStat } from "../ContestStats/ContestStats"
import "./ContestLeaderboardTable.scss"

const i18n = {
    currentHourTopCamsText: gettext("Current Hour's Top Cams"),
}

interface ContestLeaderboardTableProps {
    /** The top 10 leaders data for contest */
    contestStats: IContestStat[]
    /** Whether links within each rows should be opened in a new tab */
    openLinksInNewTab: boolean
    /** fires event router loadRoomRequest */
    loadRoomRequestFire?: (event: string) => void
}

export function ContestLeaderboardTable({
    contestStats,
    openLinksInNewTab,
    loadRoomRequestFire,
}: ContestLeaderboardTableProps) {
    return (
        <div className="ContestLeaderboardTable">
            <Typography
                weight={Weights.medium}
                component="h2"
                className="label"
                data-testid="current-hour-top-cam-label"
            >
                {i18n.currentHourTopCamsText}
            </Typography>
            <hr className="topCamsEndLine" />
            {contestStats.length > 0 &&
                contestStats.map((contestStat: IContestStat, index) => (
                    <ContestLeaderboardRow
                        {...contestStat}
                        openLinksInNewTab={openLinksInNewTab}
                        loadRoomRequestFire={loadRoomRequestFire}
                        position={index + 1}
                        key={contestStat.room_slug}
                    />
                ))}
        </div>
    )
}
