import React, { createContext, useContext, useState } from "react"
import { Alert, IAlert } from "../components/common/organisms/Alert"

/**
 * A function that adds an IAlert compatible alert to the stack.
 */
type AddAlertFn = (a: IAlert) => void

/**
 * Our alert context that passes a function to add Alerts to the Provider stack.
 */
const AlertContext = createContext<AddAlertFn>(
    undefined as unknown as AddAlertFn,
)

/**
 * This is a helpful wrapper around the AlertContext that returns a function to
 * add an Alert to the Alert Stack. Multiple alerts may be stacked and will close in the order
 * they have been added.
 *
 * @returns addAlert: `(a: IAlert) => void`
 */
export function useAlert(): AddAlertFn {
    const addAlert = useContext(AlertContext)
    if (!addAlert) {
        throw new Error("useAlert must be used under AlertProvider")
    }
    return addAlert
}

/**
 * AlertProvider allows you to utilize the alert stack, which is necessary for modal confirms and dialogs.
 *
 * @see {@link Alert} for the component that renders when you add an alert via {@link useAlert}
 * @see {@link useAlert} for how to consume the context passed from this provider.
 */
export function AlertProvider({ children }: { children: React.ReactNode }) {
    const [alerts, setAlerts] = useState<IAlert[]>([])

    const addAlert = (alert: IAlert) => {
        setAlerts((prevAlerts) => [alert, ...prevAlerts])
    }

    const closeAlert = () => {
        setAlerts((prevAlerts) => prevAlerts.slice(1))
    }

    const currentAlert = alerts[0]

    return (
        <AlertContext.Provider value={addAlert}>
            {children}
            {currentAlert && (
                <Alert {...(currentAlert as IAlert)} closeModal={closeAlert} />
            )}
        </AlertContext.Provider>
    )
}
