import "./MembershipDurationText.scss"
import { gettext } from "@multimediallc/web-utils"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Typography } from "../../common"

const i18n = {
    expires: gettext("Expires"),
    renews: gettext("Renews"),
}

interface MembershipDurationTextProps {
    cancelled: boolean
    expiredAt: string
}

export function MembershipDurationText({
    cancelled,
    expiredAt,
}: MembershipDurationTextProps) {
    const formattedExpiredAt = new Date(expiredAt).toLocaleDateString()
    return (
        <Typography
            className="MembershipDurationText"
            weight={Weights.medium}
            size={Sizes.xs}
        >
            {`${
                cancelled ? i18n.expires : i18n.renews
            }: ${formattedExpiredAt} `}
        </Typography>
    )
}
