import "./videoOverlay.scss"

interface VideoOverlayProps {
    fps: string
    bitrate: number
    resolution: string
    framesLost: string
    latency: string
    streamType: string
    avShift: string
    startTime: number
}

export function VideoOverlay({
    fps,
    bitrate,
    resolution,
    framesLost,
    latency,
    streamType,
    avShift,
    startTime,
}: VideoOverlayProps) {
    return (
        <div className="video-overlay">
            <div className="video-overlay-item">Stream Type: {streamType}</div>
            <div className="video-overlay-item">FPS Quality: {fps}</div>
            <div className="video-overlay-item">Bitrate: {bitrate} kbps</div>
            <div className="video-overlay-item">Resolution: {resolution}</div>
            <div className="video-overlay-item">Frames lost: {framesLost}%</div>
            <div className="video-overlay-item">Latency: {latency}s</div>
            <div className="video-overlay-item">AV shift: {avShift}s</div>
            <div className="video-overlay-item">Start Time: {startTime}ms</div>
        </div>
    )
}
