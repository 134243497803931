import type { Ref, SVGProps } from "react"
import { forwardRef, memo } from "react"

interface SVGRProps {
    title?: string
    titleId?: string
}
const SvgMale = (
    { title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps,
    ref: Ref<SVGSVGElement>,
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 12 12"
        role="img"
        ref={ref}
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="url(#male_svg__a)"
            d="M11.228 11.79H.907a.544.544 0 0 1-.574-.51V9.356c0-.281.27-.311.573-.51l5.16-2.644 5.16 2.652c.338.21.574.228.574.51v1.916a.543.543 0 0 1-.574.51"
        />
        <path
            fill="url(#male_svg__b)"
            d="M6.964 11.374V7.106l4.159 1.819q.14.083.258.139c.21.105.21.116.21.176v1.849a.337.337 0 0 1-.375.288H6.964zm-6.057 0a.337.337 0 0 1-.375-.289V9.236c0-.06.02-.082.222-.18q.13-.063.255-.138L5.242 7.06v4.313z"
        />
        <path
            fill="url(#male_svg__c)"
            d="M6.964 11.374V7.106l4.159 1.819q.14.083.258.139c.21.105.21.116.21.176v1.849a.337.337 0 0 1-.375.288H6.964zm-6.057 0a.337.337 0 0 1-.375-.289V9.236c0-.06.02-.082.222-.18q.13-.063.255-.138L5.242 7.06v4.313z"
        />
        <path fill="url(#male_svg__d)" d="M5.209 8.587H6.93v3.203H5.209z" />
        <path
            fill="url(#male_svg__e)"
            d="m7.553 8.565-.225.289H4.804l-.221-.289V5.494h2.97z"
        />
        <path
            fill="url(#male_svg__f)"
            d="M8.693 2.981a.32.32 0 0 0-.263.154v-.832c0-.428-.251-2.13-1.987-2.13h-.86c-1.5 0-1.923 1.702-1.923 2.13v.78a.3.3 0 0 0-.217-.102.375.375 0 0 0-.33.375l.093.882a.375.375 0 0 0 .33.375.3.3 0 0 0 .214-.094v.892c0 .428 1.463 1.77 1.841 1.77h.852c.375 0 1.848-1.361 1.848-1.788v-.837a.32.32 0 0 0 .263.154.375.375 0 0 0 .33-.375l.139-.979a.375.375 0 0 0-.33-.375"
        />
        <path
            fill="url(#male_svg__g)"
            d="M8.693 2.981a.32.32 0 0 0-.263.154v-.832c0-.428-.251-2.13-1.987-2.13h-.86c-1.5 0-1.923 1.702-1.923 2.13v.78a.3.3 0 0 0-.217-.102.375.375 0 0 0-.33.375l.093.882a.375.375 0 0 0 .33.375.3.3 0 0 0 .214-.094v.892c0 .428 1.463 1.77 1.841 1.77h.852c.375 0 1.848-1.361 1.848-1.788v-.837a.32.32 0 0 0 .263.154.375.375 0 0 0 .33-.375l.139-.979a.375.375 0 0 0-.33-.375"
        />
        <path
            fill="#4C3D3D"
            d="M8.43 2.303c0-.428-.113-2.13-1.969-2.13h-.926c-1.616 0-1.875 1.702-1.875 2.13 0 0-.158 1.875.259 2.25l.15-1.65c0-.428.375-1.125 1.053-.93.628.149 1.282.149 1.91 0 .494-.143.88.483.88.91l.203 1.68c.36-.34.315-2.26.315-2.26"
        />
        <rect
            width={2.75}
            height={1.45}
            x={4.65}
            y={0.25}
            fill="url(#male_svg__h)"
            opacity={0.5}
            rx={0.725}
            style={{
                mixBlendMode: "screen",
            }}
        />
    </svg>
)
const ForwardRef = forwardRef(SvgMale)
const Memo = memo(ForwardRef)
export default Memo
